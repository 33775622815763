import {
  FundOutlined,
  HighlightOutlined,
  InboxOutlined,
} from "@ant-design/icons";

import { datePickerFormat } from "@/utils/date";
import { SolutionOutlined, UserOutlined } from "@ant-design/icons";
import { Edit, useStepsForm } from "@refinedev/antd";
import {
  IResourceComponentsProps,
  useApiUrl,
  useGo,
  useInvalidate,
} from "@refinedev/core";
import { useDocumentTitle } from "@refinedev/react-router-v6";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Result,
  Row,
  Select,
  Space,
  Steps,
  Upload,
  message,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ProfileForm } from "../../components/users/ProfileForm";
// import { ProjectExperienceForm } from "../../components/users/ProjectExperienceForm";
import { WorkPreferenceForm } from "../../components/users/WorkPreferenceForm";
import { useRefreshToken } from "../../hooks";
import { IProjectExperience, IWorkExperience } from "../../interfaces";
import { IUserInfo } from "../../interfaces";
import {
  convertUploadListToId,
  makeUploadFileObject,
} from "../../utils/common";
import { onAvatarPreview } from "../../utils/dev";
import { axiosInstance } from "../../utils/http";

const { TextArea } = Input;
const { Option } = Select;
const { Dragger } = Upload;
const resumeDataIndex = [
  "profile",
  "work_preference",
  "project_experience",
  "work_experience",
];

const formatInitialValues = (initialValues: IUserInfo | undefined) => {
  if (!initialValues) return initialValues;
  return {
    ...initialValues,
    project_experience: initialValues.project_experience.map((project) => ({
      ...project,
      start_date: project.start_date ? dayjs(project.start_date) : null,
      end_date: project.end_date ? dayjs(project.end_date) : null,
    })),
    work_experience: initialValues.work_experience.map((work) => ({
      ...work,
      start_date: work.start_date ? dayjs(work.start_date) : null,
      end_date: work.end_date ? dayjs(work.end_date) : null,
    })),
  };
};

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
  useDocumentTitle("Edit Resume | LeyLine");
  const {
    current,
    gotoStep,
    stepsProps,
    formProps,
    saveButtonProps,
    queryResult,
  } = useStepsForm({
    resource: "users",
    id: "profile",
    warnWhenUnsavedChanges: false,
  });
  const formattedInitialValues = formatInitialValues(
    formProps?.initialValues as IUserInfo,
  );

  const apiUrl = useApiUrl();
  const go = useGo();
  const { refreshToken } = useRefreshToken();
  // const { location, error: locationError } = useGeolocation();

  useEffect(() => {
    if (formProps) {
      console.log(formProps);
      console.log(saveButtonProps);
      // console.log(location);
      // console.log(initialValues);
      console.log(formattedInitialValues);
    }
  }, [formProps, saveButtonProps, formattedInitialValues]);

  const useRefresh = () => {
    const invalidate = useInvalidate();

    const refresh = () => {
      invalidate({
        resource: "users",
        invalidates: ["all"],
      });
    };
    return refresh;
  };
  const refresh = useRefresh();

  // useEffect(() => {
  //   const resumeDataIndex = [
  //     "profile",
  //     "work_preference",
  //     "project_experience",
  //     "work_experience",
  //   ];
  //   const key = resumeDataIndex[current];
  //   const values = formProps?.initialValues?.[key];
  //   console.log(current, key);
  //   // console.log(formProps?.initialValues?.[resumeDataIndex[current]]);
  // }, [formProps, current]);

  // Step 1: Profile  <ProfileForm />
  // Step 2: Work Preference  <WorkPreferenceForm / >

  // Step 3: Project Experience
  const projectExpFormStep3 = (
    <>
      <Form.List name="project_experience">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      {...restField}
                      label="Project Name"
                      name={[name, "name"]}
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      label="Role"
                      name={[name, "role"]}
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      label="Start Date"
                      name={[name, "start_date"]}
                      rules={[{ required: true }]}
                    >
                      <DatePicker format={datePickerFormat} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="End Date"
                      name={[name, "end_date"]}
                      rules={[{ required: true }]}
                    >
                      <DatePicker format={datePickerFormat} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Project Description"
                      name={[name, "description"]}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    {/* project cover */}
                    <Form.Item
                      {...restField}
                      label="Page Cover"
                      name={[name, "cover_url"]}
                      rules={[
                        {
                          required: true,
                          message: "Please upload a page cover",
                        },
                      ]}
                    >
                      <Dragger
                        maxCount={1}
                        listType="picture"
                        onPreview={onAvatarPreview}
                        //  onChange={handlePageCoverUploadChange}
                        // beforeUpload={beforeUpload}
                        action={`${apiUrl}/users/upload`}
                        headers={{
                          authorization: `Bearer ${localStorage.getItem(
                            "leyline-auth",
                          )}`,
                        }}
                        accept="image/*"
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                      </Dragger>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    {/* <Row>
                      <Button onClick={() => autoFill(formProps, name, 2)}>
                        Autofill the form
                      </Button>
                    </Row> */}

                    <Row>
                      <Space>
                        <Button
                          type="default"
                          onClick={() => {
                            remove(name);
                            onRemoveExperience(name, "project_experience");
                          }}
                        >
                          Delete
                        </Button>
                        {/* <SaveButton
                          style={{ color: "white" }}
                          type="primary"
                          htmlType="submit"
                        /> */}
                      </Space>
                    </Row>
                  </Col>
                </Row>
                <Divider />
              </div>
            ))}
            <Space>
              <Button
                type="dashed"
                style={{ marginRight: "12px" }}
                onClick={() => add()}
              >
                Add Project Experience
              </Button>
            </Space>
          </>
        )}
      </Form.List>
      {/* <Button type="primary" htmlType="submit">
        Save Projects
      </Button> */}
    </>
  );

  // Step 4: Work Experience
  const workExpFormStep4 = (
    <>
      <Form.List name="work_experience">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      {...restField}
                      name={[name, "title"]}
                      label="Title"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "company_name"]}
                      label="Company Name"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "department_name"]}
                      label="Department Name"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "city"]}
                      label="City"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "country"]}
                      label="Country"
                    >
                      <Input />
                    </Form.Item>
                    <Row gutter={16}>
                      {/* <Space> */}
                      <Col>
                        <Form.Item
                          {...restField}
                          name={[name, "start_date"]}
                          label="Start Date"
                          rules={[{ required: true }]}
                        >
                          <DatePicker format={datePickerFormat} />
                        </Form.Item>
                      </Col>
                      {/* <Form.Item
                        {...restField}
                        name={[name, "end_date"]}
                        label="End Date"
                        rules={[{ required: true }]}
                      >
                        <DatePicker format={datePickerFormat}/>
                      </Form.Item> */}
                      <Col>
                        <Form.Item
                          {...restField}
                          name={[name, "is_current"]}
                          valuePropName="checked"
                          label="Is Current Job"
                        >
                          <Checkbox>Yes </Checkbox>
                        </Form.Item>
                      </Col>
                      {/* </Space> */}
                    </Row>

                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue }) => {
                        const isCurrent = getFieldValue([
                          "work_experience",
                          name,
                          "is_current",
                        ]);
                        return isCurrent === 1 || isCurrent === true ? null : (
                          <Form.Item
                            {...restField}
                            name={[name, "end_date"]}
                            label="End Date"
                            rules={[{ required: true }]}
                          >
                            <DatePicker format={datePickerFormat} />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "description"]}
                      label="Description"
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>
                </Row>
                {/* <Row>
                    <Button onClick={() => autoFill(formProps, name, 3)}>
                      Autofill the form
                    </Button>
                  </Row> */}
                <Row>
                  <Space>
                    <Button
                      type="default"
                      onClick={() => {
                        remove(name);
                        onRemoveExperience(name, "work_experience");
                      }}
                    >
                      Delete
                    </Button>
                    {/* <SaveButton
                        style={{ color: "white" }}
                        type="primary"
                        htmlType="submit"
                      /> */}
                  </Space>
                </Row>
                <Divider />
              </div>
            ))}
            <Button
              type="dashed"
              style={{ marginRight: "12px" }}
              onClick={() => add()}
            >
              Add Work Experience
            </Button>
          </>
        )}
      </Form.List>
      {/* <Button type="primary" htmlType="submit">
        Save Work Experience
      </Button> */}
    </>
  );

  const completedForm = (
    <>
      <Row key="Submit" gutter={20}>
        <Col span={24}>
          <Result
            status="success"
            title="Submit Successfully"
            subTitle="Your Resume has been updated successfully."
            extra={[
              <Link to="/users/home" key="check-details">
                <Button>To HomePage</Button>
              </Link>,
            ]}
          />
        </Col>
      </Row>
    </>
  );
  const formList = [
    ProfileForm,
    WorkPreferenceForm,
    projectExpFormStep3,
    workExpFormStep4,
  ];

  // biome-ignore lint:
  const onFinish = async (values: any) => {
    try {
      if (current === 0) {
        values.avatar_url = makeUploadFileObject(values.avatar_url);
        values.page_cover_url = makeUploadFileObject(values.page_cover_url);
        await axiosInstance.put("/users/profile", values);
        await refreshToken();
        refresh();
        message.success("Account profile updated successfully");
        gotoStep(current + 1);
      }
      if (current === 1) {
        await axiosInstance.put("/users/work-preference", values);
        message.success("Profile updated successfully");
        gotoStep(current + 1);
      }
      if (current === 2) {
        const projectExperienceList = values.project_experience.map(
          (proj: IProjectExperience) => {
            return {
              ...proj,
              //@ts-ignore
              start_date: proj?.start_date?.format("YYYY-MM-DD HH:mm:ss"),
              //@ts-ignore
              end_date: proj?.end_date?.format("YYYY-MM-DD HH:mm:ss"),
              cover_url: convertUploadListToId(proj.cover_url),
            };
          },
        );
        await Promise.all(
          projectExperienceList.map((proj: IProjectExperience) =>
            axiosInstance.put("/users/project-experience", proj),
          ),
        );
        message.success("Project Experience saved successfully");
        gotoStep(current + 1);
      }
      if (current === 3) {
        // biome-ignore lint:
        const workExperienceList = values.work_experience.map((work: any) => {
          return {
            ...work,
            start_date: work?.start_date?.format("YYYY-MM-DD HH:mm:ss"),
            end_date: work?.end_date?.format("YYYY-MM-DD HH:mm:ss"),
            is_current: work?.is_current ? 1 : 0,
          };
        });
        await Promise.all(
          workExperienceList.map((work: IWorkExperience) =>
            axiosInstance.put("/users/work-experience", work),
          ),
        );
        message.success("Your resume has been successfully created!", 3);
        gotoStep(current + 1);
      }
    } catch (error) {
      console.log(error);
      // @ts-ignore
      message.error(error?.message + error?.errors?.detail);
    }
    console.log("Success:", values);
  };

  const onFinishFailed = () => {
    console.log("Finish Failed:");
  };
  // biome-ignore lint:
  const onValuesChange = (changedValues: any, allValues: any) => {
    console.log("valuesChanges", changedValues);
  };

  // @ts-ignore
  const onRemoveExperience = async (index, type: string) => {
    console.log("remove experience", type);
    try {
      // @ts-ignore
      const values = formattedInitialValues[type]?.[index] as
        | IProjectExperience
        | IWorkExperience;
      if (type === "project") {
        await axiosInstance.put("/users/project-experience", {
          id: values.id,
          is_deleted: 1,
        });
      } else {
        await axiosInstance.put("/users/work-experience", {
          id: values.id,
          is_deleted: 1,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveAndNext = () => {
    if (!formProps?.form?.isFieldsTouched()) {
      gotoStep(current + 1);
    } else {
      formProps?.form?.validateFields({ validateOnly: false }).then(
        (v) => {
          console.log("validate ok", v);
          onFinish(v);
        },
        (e) => {
          console.error(e);
        },
      );
    }
  };
  const formHeaderButtons = (
    <>
      {current > 0 && (
        <Button
          onClick={() => {
            gotoStep(current - 1);
          }}
        >
          Previous
        </Button>
      )}
      {current < formList.length - 1 && (
        <Button
          onClick={() => {
            saveAndNext();
          }}
        >
          Next
        </Button>
      )}
      {current === formList.length - 1 && (
        <Button
          type="primary"
          onClick={() => {
            saveAndNext();
          }}
        >
          Done
        </Button>
      )}
    </>
  );

  return (
    <Edit
      isLoading={queryResult?.isLoading}
      resource="/users/profile"
      headerButtons={formHeaderButtons}
      footerButtons={formHeaderButtons}
      title={"Edit Resume"}
    >
      <Steps {...stepsProps}>
        <Steps.Step title="Profile" icon={<UserOutlined />} />
        <Steps.Step title="Preference" icon={<HighlightOutlined />} />
        <Steps.Step title="Project Experience" icon={<FundOutlined />} />
        <Steps.Step title="Work Experience" icon={<SolutionOutlined />} />
        {/* <Steps.Step title="Done" icon={<SmileOutlined />} /> */}
      </Steps>

      {(current === 0 || current === 4) && (
        <Form
          // {...formProps}
          form={formProps?.form}
          layout="vertical"
          style={{ marginTop: 30 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
          // @ts-ignore
          initialValues={formattedInitialValues?.[resumeDataIndex[current]]}
        >
          {/* {formList[current]} */}
          {current === 0 && (
            <ProfileForm
              formProps={formProps}
              onAvatarPreview={onAvatarPreview}
            />
          )}

          {current === 4 && completedForm}

          {/* <SaveButton
          {...saveButtonProps}
          style={{ marginTop: 30 }}
          type="primary"
          htmlType="submit"
        /> */}
        </Form>
      )}

      {current === 1 && (
        <Form
          // {...formProps}
          form={formProps?.form}
          layout="vertical"
          style={{ marginTop: 30 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
          initialValues={formattedInitialValues?.work_preference}
        >
          {current === 1 && (
            <WorkPreferenceForm
              formProps={formProps}
              onAvatarPreview={onAvatarPreview}
            />
          )}
        </Form>
      )}

      {current === 2 && (
        <Form
          // {...formProps}
          form={formProps?.form}
          layout="vertical"
          style={{ marginTop: 30 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
          initialValues={formattedInitialValues}
        >
          {current === 2 && projectExpFormStep3}
        </Form>
      )}
      {current === 3 && (
        <Form
          // {...formProps}
          form={formProps?.form}
          layout="vertical"
          style={{ marginTop: 30 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
          initialValues={{
            work_experience: formattedInitialValues?.work_experience,
          }}
        >
          {current === 3 && workExpFormStep4}
        </Form>
      )}
    </Edit>
  );
};
