import themeConfig from "@/constants/themeConfig";
import { PortfolioDetail } from "@/pages/discover/PortfolioDetail";
import { useArtworkStore } from "@/stores";
import { ThemedLayoutContextProvider } from "@refinedev/antd";
import type { RefineThemedLayoutV2Props } from "@refinedev/antd";
import { useParsed } from "@refinedev/core";
import { ConfigProvider, Layout as AntdLayout } from "antd";
import React from "react";
import { CustomizedThemedHeader as DefaultHeader } from "./header";
export const CustomizedThemedLayout: React.FC<RefineThemedLayoutV2Props> = ({
  children,
  Header,
  Footer,
  initialSiderCollapsed = false,
}) => {
  const { artworkDetailModalVisible } = useArtworkStore((state) => state);
  const HeaderToRender = Header ?? DefaultHeader;
  const { pathname } = useParsed();
  const isDiscoverPath =
    pathname === "/" ||
    pathname === "/homepage" ||
    pathname?.includes("/portfolio") ||
    pathname?.includes("/discover/author/") ||
    pathname === "/companies" ||
    pathname === "/companies/homepage" ||
    pathname?.includes("/enterprises/public-company-homepage") ||
    pathname?.includes("/enterprises/public-jobs") ||
    pathname?.includes("/enterprises/public-job-details/") ||
    pathname?.includes("/users/profile") ||
    pathname?.includes("/login") ||
    pathname?.includes("/forgot-password") ||
    pathname?.includes("/register") ||
    pathname?.includes("/novice-guide");

  return (
    <ThemedLayoutContextProvider initialSiderCollapsed={initialSiderCollapsed}>
      <AntdLayout className="min-h-screen" hasSider={false}>
        <AntdLayout>
          <HeaderToRender />
          <AntdLayout.Content
            className={`h-[calc(100vh-80px)] bg-[${
              isDiscoverPath ? "#1D1F22" : "#fff"
            }]`}
          >
            {isDiscoverPath ? (
              <ConfigProvider theme={themeConfig}>
                <div>{children}</div>
                {artworkDetailModalVisible && <PortfolioDetail />}
              </ConfigProvider>
            ) : (
              <div>{children}</div>
            )}
            {/* {OffLayoutArea && <OffLayoutArea />} */}
          </AntdLayout.Content>
          {Footer && <Footer />}
        </AntdLayout>
      </AntdLayout>
    </ThemedLayoutContextProvider>
  );
};
