import { ICompanyInfo } from "@/pages/companies/type";
import { formatToMiddleStyle, formatToMinStyle } from "@/utils/date";
import { EditOutlined } from "@ant-design/icons";
import {
  IResourceComponentsProps,
  useGo,
  useInvalidate,
  useShow,
} from "@refinedev/core";
import { Alert, Button, Col, Row, Space, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const { Text, Title } = Typography;

//@ts-ignore
const CompanyTable = ({ companyData }) => {
  const data = {
    country: companyData.extended_info.country || "N/A",
    companyName: companyData.extended_info.name || "N/A",
    companyAbbreviation: companyData.extended_info.shortname || "N/A",
    companyType: companyData.extended_info.type || "N/A",
    establishmentDate: companyData.extended_info.established_at
      ? formatToMinStyle(companyData.extended_info.established_at)
      : "N/A",
    website: companyData.website || "N/A",
    applicant: companyData.applicant_name || "N/A",
    applicantRole: companyData.applicant_role || "N/A",
    applicantEmail: companyData.applicant_email || "N/A",
  };

  return (
    <div className="container mx-auto my-4 p-4">
      <table className="table-auto w-full text-left border-collapse">
        <tbody>
          <tr className="border-b">
            <td className="px-4 py-2 font-bold bg-gray-100">Country</td>
            <td className="px-4 py-2" colSpan={3}>
              {data.country}
            </td>
          </tr>
          <tr className="border-b">
            <td className="px-4 py-2 font-bold bg-gray-100">Company Name</td>
            <td className="px-4 py-2">{data.companyName}</td>
            <td className="px-4 py-2 font-bold bg-gray-100">
              Company Abbreviation
            </td>
            <td className="px-4 py-2">{data.companyAbbreviation}</td>
          </tr>
          <tr className="border-b">
            <td className="px-4 py-2 font-bold bg-gray-100">Company Type</td>
            <td className="px-4 py-2">{data.companyType}</td>
            <td className="px-4 py-2 font-bold bg-gray-100">
              Establishment Year
            </td>
            <td className="px-4 py-2">{data.establishmentDate}</td>
          </tr>
          <tr className="border-b">
            <td className="px-4 py-2 font-bold bg-gray-100">Website</td>
            <td className="px-4 py-2" colSpan={3}>
              {data.website}
            </td>
          </tr>
          <tr className="border-b">
            <td className="px-4 py-2 font-bold bg-gray-100">Your Name</td>
            <td className="px-4 py-2">{data.applicant}</td>
            <td className="px-4 py-2 font-bold bg-gray-100">Your Role</td>
            <td className="px-4 py-2">{data.applicantRole}</td>
          </tr>
          <tr className="border-b">
            <td className="px-4 py-2 font-bold bg-gray-100">
              Your Business Email
            </td>
            <td className="px-4 py-2" colSpan={3}>
              {data.applicantEmail}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const CompanyShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<ICompanyInfo>({
    resource: "companies",
    id: "my-company",
  });
  const { data, isLoading } = queryResult;

  const go = useGo();
  const companyData = queryResult?.data?.data;

  const { verification_status: applyStatus = "UNSUBMITTED" } =
    companyData || {};

  function goToVerify() {
    go({
      to: "/companies/create",
    });
  }
  const useRefresh = () => {
    const invalidate = useInvalidate();

    const refresh = () => {
      invalidate({
        resource: "companies",
        invalidates: ["all"],
      });
    };
    return refresh;
  };

  const refresh = useRefresh();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const initialize = async () => {
      await refresh();
    };
    initialize();
  }, [companyData]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className={`bg-white min-h-screen ${
        applyStatus === "UNSUBMITTED"
          ? "flex flex-col items-center p-40"
          : "p-20"
      }`}
    >
      {applyStatus === "UNSUBMITTED" ? (
        <Space direction="vertical" size="large">
          <Alert
            message={
              <>
                <Title level={3}>Verify your company with LeyLine</Title>
                <Text>
                  Adding a verification to your company means you have confirmed
                  specific information about your account. This helps build
                  credibility and trust.
                </Text>
                <br />
                <Text>Verifications are always free.</Text>
                <br />
              </>
            }
            type="warning"
          />
          <Row justify="center">
            <Col>
              <Space direction="vertical" size="large">
                <Title level={2}>
                  Benefits of a verified account encompass
                </Title>
                <li>Verification badge</li>
                <li>Project management features</li>
                <li>Enhanced visibility and trust</li>
                <li>Superior customer service support</li>
                <div className="flex justify-center mt-10">
                  <Button type="primary" onClick={() => goToVerify()}>
                    Submit Company Information
                  </Button>
                </div>
              </Space>
            </Col>
          </Row>
        </Space>
      ) : (
        <div className="w-full space-y-4">
          <div className="flex justify-end space-x-2">
            {applyStatus === "DENIED" && (
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => {
                  goToVerify();
                }}
              >
                Modify Company Information
              </Button>
            )}
            {applyStatus === "APPROVED" && (
              <Link to="/companies/homepage" key="to-homepage">
                <Button type="primary" icon={<EditOutlined />}>
                  To Company Homepage
                </Button>
              </Link>
            )}
          </div>
          {applyStatus === "PENDING" && (
            <Alert
              message="Reviewing"
              description="Please wait for the review to be completed (expect 1-3 business days)."
              type="warning"
              showIcon
            />
          )}
          {applyStatus === "DENIED" && (
            <Alert
              message="Denied"
              description={`Feedback: ${companyData?.comments}`}
              type="error"
              showIcon
            />
          )}
          {applyStatus === "APPROVED" && (
            <Alert
              message="Company information has been verified"
              description="Go ahead and complete company homepage!"
              type="success"
              showIcon
            />
          )}
          <CompanyTable companyData={companyData} />
        </div>
      )}
    </div>
  );
};
