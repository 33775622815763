import { useMyLogout } from "@/components/sider/utils";
import { passwordValidator } from "@/pages/authCore/utils";
import { generateTimezoneOptions, guessUserTimezone } from "@/utils/date";
import { axiosInstance, httpPut } from "@/utils/http";
import { LockOutlined } from "@ant-design/icons";
import { Create } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { useRequest } from "ahooks";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Switch,
  Typography,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";

const { Option } = Select;
const { Text, Link } = Typography;

export const SettingsPage: React.FC = () => {
  const formRef = useRef<FormInstance>(null);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [openNotification, setOpenNotification] = useState(true);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const translate = useTranslate();
  const handleLogout = useMyLogout();

  const timezoneOptions = generateTimezoneOptions();

  const { loading, run: replacePasswordApi } = useRequest(
    (values) =>
      httpPut("/auth/replace-password", {
        old_password: values.old_password,
        new_password: values.new_password1,
      }),
    {
      manual: true,
      onSuccess() {
        message.success("Password changed successfully!");
        setShowPasswordForm(false);
        handleLogout();
      },
      onError(error) {
        // @ts-ignore
        message.error(error?.errors?.detail || "Failed to update setting.");
      },
    },
  );

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axiosInstance.get("/users/settings");
        let timezone = response.data.timezone;
        if (!timezone) {
          timezone = guessUserTimezone();
        }
        form.setFieldsValue({
          email: response.data.email,
          timezone,
        });
        setOpenNotification(response.data.is_im_email_subscribed);
        localStorage.setItem("userTimezone", timezone);
      } catch (error) {
        console.error("Failed to fetch user profile: ", error);
        message.error("Failed to load user profile");
      }
    };

    fetchUserProfile();
  }, [form]);

  const onChangeNotification = (e: boolean) => {
    setOpenNotification(e);
    handleSave({
      is_im_email_subscribed: e,
    });
  };

  const handleSave = async (saveArgs?: { is_im_email_subscribed: boolean }) => {
    try {
      const values = await form.validateFields();
      console.log("Form values:", values);

      await axiosInstance.put(
        "/users/settings",
        saveArgs ?? {
          email: values.email,
          timezone: values.timezone,
        },
      );

      localStorage.setItem("userTimezone", values.timezone);

      message.success("Setting updated successfully! ");
    } catch (error) {
      console.log("Update Failed:", error);
      message.error("Failed to update setting.");
    }
  };

  const handleSave2 = async (values: {
    old_password: string;
    new_password1: string;
  }) => {
    replacePasswordApi(values);
  };

  return (
    <Row gutter={24} className="min-h-screen">
      <Col span={24} className="overflow-auto">
        <div className={`rounded-lg bg-white p-4 ${styles.setting}`}>
          <Create
            title="Settings"
            headerButtons={() => <></>}
            footerButtons={() => null}
          >
            <Form
              ref={formRef}
              form={form}
              className="max-w-[50%] mx-auto"
              layout="horizontal"
              labelCol={{ span: 7 }}
              initialValues={{
                language: "English",
              }}
              onFinish={() => handleSave()}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: "Please enter your email" }]}
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                label="Language"
                name="language"
                rules={[
                  { required: true, message: "Please select a language" },
                ]}
                extra={<Text type="secondary">Interface Language</Text>}
              >
                <Select defaultValue="English">
                  <Option value="English">English</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Timezone"
                name="timezone"
                rules={[
                  { required: true, message: "Please select a timezone" },
                ]}
                extra={
                  <Text type="secondary">
                    Default Timezone Settings for LeyLine
                  </Text>
                }
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option?.children
                      ? (option.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      : false
                  }
                >
                  {timezoneOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item className="text-center pl-[30%]">
                <Button
                  type="primary"
                  onClick={() => formRef.current?.submit()}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Create>
          <Form
            form={form2}
            layout="horizontal"
            labelCol={{ span: 7 }}
            initialValues={{
              language: "English",
            }}
            className="max-w-[50%] mx-auto"
            onFinish={handleSave2}
          >
            <Form.Item
              label="IM Email Notification"
              name="im_notification"
              extra="Once enabled, all chat messages you receive will also be sent to you via email."
            >
              <Switch
                value={openNotification}
                onChange={onChangeNotification}
                checkedChildren="ON"
                unCheckedChildren="OFF"
              />
            </Form.Item>
            <Divider />
            <Form.Item
              label="Password"
              className={showPasswordForm ? "hidden" : ""}
            >
              <Link onClick={() => setShowPasswordForm(true)}>
                Change Password
              </Link>
            </Form.Item>
            {showPasswordForm && (
              <>
                <Form.Item
                  label="Password"
                  required={false}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  name="old_password"
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Your Current Password"
                    allowClear
                  />
                </Form.Item>
                <Form.Item
                  colon={false}
                  label=" "
                  name="new_password1"
                  required={false}
                  rules={[
                    {
                      required: true,
                      validator: passwordValidator,
                    },
                  ]}
                  extra={
                    <span className="leading-4">
                      At least 8+ characters with at least one digit, one
                      uppercase, one lowercase, and one special character.
                    </span>
                  }
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="New Password"
                    allowClear
                  />
                </Form.Item>
                <Form.Item
                  colon={false}
                  label=" "
                  name="new_password2"
                  dependencies={["password"]}
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: translate(
                        "pages.register.errors.confirmPassword",
                        "Please confirm your password!",
                      ),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          getFieldValue("new_password1") === value
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            translate(
                              "pages.register.errors.passwordMatch",
                              "The two passwords do not match",
                            ),
                          ),
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Confirm Password"
                    allowClear
                  />
                </Form.Item>
                <Form.Item className="text-center pl-[30%]">
                  <Button
                    className="mr-3"
                    onClick={() => setShowPasswordForm(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    loading={loading}
                    type="primary"
                    onClick={() => form2.submit()}
                  >
                    Save
                  </Button>
                </Form.Item>
              </>
            )}
          </Form>
        </div>
      </Col>
    </Row>
  );
};
