import { CommentDrawer } from "@/components/users/components/notificationDrawer/CommentDrawer";
import { FollowerDrawer } from "@/components/users/components/notificationDrawer/FollowerDrawer";
import { LikeCollectionDrawer } from "@/components/users/components/notificationDrawer/LikeCollectionDrawer";
import {
  getNotificationUnreadCountAPI,
  getTotalNotificationUnreadCountAPI,
} from "@/pages/discover/api";
import { useGlobalStore } from "@/stores";
import { CloseOutlined } from "@ant-design/icons";
import {
  Badge,
  Col,
  Divider,
  Drawer,
  Modal,
  Row,
  Space,
  Tooltip,
  message,
} from "antd";
import React, { useCallback, useEffect } from "react";

interface INotificationDrawerProps {
  setVisible: (visible: boolean) => void;
  visible: boolean;
}
export const NotificationDrawer: React.FC<INotificationDrawerProps> = ({
  setVisible,
  visible,
}) => {
  const { currentRole } = useGlobalStore((state) => state);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [notifState, setNotifState] = React.useState("comment");
  const [notifData, setNotifData] = React.useState("");
  const [unreadCommentCount, setUnreadCommentCount] = React.useState<number>(0);
  const [unreadLikeCount, setUnreadLikeCount] = React.useState<number>(0);
  const [unreadFollowCount, setUnreadFollowCount] = React.useState<number>(0);
  const onClose = () => {
    setVisible(false);
  };

  // biome-ignore lint:
  const getUnreadCount = useCallback(async () => {
    if (!currentRole) return;
    const response = await getTotalNotificationUnreadCountAPI(currentRole);
    setUnreadCommentCount(response.comment);
    setUnreadLikeCount(response.like_and_collection);
    setUnreadFollowCount(response.follow);
  }, [currentRole, notifState, notifData]);

  useEffect(() => {
    getUnreadCount();
  }, [getUnreadCount]);

  return (
    <Drawer
      open={visible}
      // title={`${applyInfo ? "Modify" : "Create"} application information`}
      title={
        <div className="flex flex-row justify-between">
          <span className="text-white text-base">Notification</span>
          <CloseOutlined className="text-[#686868] ml-2.5" onClick={onClose} />
        </div>
      }
      styles={{
        header: {
          background: "#2A2D30",
          height: "62px",
          padding: "20px",
        },
        body: {
          background: "#232529",
          padding: "0px 0px",
        },
        content: {
          position: "absolute",
          height: "calc(100vh - 80px)",
          bottom: 0,
        },
      }}
      placement="right"
      closable={false}
      width={532}
      maskClosable={false}
      mask={false}
      destroyOnClose
    >
      <Row className="h-full">
        <Col className="w-full bg-[#232529] p-[15px] z-[1000] fixed">
          <Col className="flex flex-row gap-[22px] w-full text-base text-[#999999]">
            <Col>
              <Space
                className={`${notifState === "comment" && "text-white"}`}
                onClick={() => setNotifState("comment")}
              >
                Comment
                {unreadCommentCount > 0 && (
                  <Space className="text-xs text-white px-2 bg-[#ff4d4f] rounded-full">
                    {unreadCommentCount}
                  </Space>
                )}
              </Space>
            </Col>
            <Col>
              <Space
                className={`${notifState === "collection" && "text-white"}`}
                onClick={() => setNotifState("collection")}
              >
                Like & Collection
                {unreadLikeCount > 0 && (
                  <Space className="text-xs text-white px-2 bg-[#ff4d4f] rounded-full">
                    {unreadLikeCount}
                  </Space>
                )}
              </Space>
            </Col>
            <Col>
              <Space
                className={`${notifState === "follower" && "text-white"}`}
                onClick={() => setNotifState("follower")}
              >
                New Follower
                {unreadFollowCount > 0 && (
                  <Space className="text-xs text-white px-2 bg-[#ff4d4f] rounded-full">
                    {unreadFollowCount}
                  </Space>
                )}
              </Space>
            </Col>
          </Col>
        </Col>
        <Col
          className="pt-[54px] w-full h-full overflow-auto"
          ref={containerRef}
        >
          {notifState === "comment" && (
            <CommentDrawer
              setNotifData={setNotifData}
              containerRef={containerRef}
            />
          )}
          {notifState === "collection" && (
            <LikeCollectionDrawer containerRef={containerRef} />
          )}
          {notifState === "follower" && (
            <FollowerDrawer containerRef={containerRef} />
          )}
        </Col>
      </Row>
    </Drawer>
  );
};
