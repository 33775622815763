import { DownOutlined } from "@ant-design/icons";
import { useApiUrl } from "@refinedev/core";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Input,
  Menu,
  Row,
  Space,
  Tag,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ptLinkWithState } from "../../utils/helper";

const SearchHeader: React.FC<{
  searchKeyword: string;
  setSearchKeyword: (keyword: string) => void;
  selectedProjectStatus: string[];
  setSelectedProjectStatus: (status: string[]) => void;
  selectedVerificationStatus: string[];
  setSelectedVerificationStatus: (status: string[]) => void;
  isViolated: boolean;
  setIsViolated: (violated: boolean) => void;
}> = ({
  searchKeyword,
  setSearchKeyword,
  selectedProjectStatus,
  setSelectedProjectStatus,
  selectedVerificationStatus,
  setSelectedVerificationStatus,
  isViolated,
  setIsViolated,
}) => {
  const addProjectStatus = (option: string) => {
    if (!selectedProjectStatus.includes(option)) {
      setSelectedProjectStatus([...selectedProjectStatus, option]);
    }
  };

  const removeProjectStatus = (option: string) => {
    setSelectedProjectStatus(
      selectedProjectStatus.filter((status) => status !== option),
    );
  };

  const addVerificationStatus = (option: string) => {
    if (!selectedVerificationStatus.includes(option)) {
      setSelectedVerificationStatus([...selectedVerificationStatus, option]);
    }
  };

  const removeVerificationStatus = (option: string) => {
    setSelectedVerificationStatus(
      selectedVerificationStatus.filter((status) => status !== option),
    );
  };

  // Function to render project status tags
  const renderProjectStatusTags = () =>
    selectedProjectStatus.map((status) => (
      <Tag closable onClose={() => removeProjectStatus(status)} key={status}>
        {status}
      </Tag>
    ));

  // Function to render verification status tags
  const renderVerificationStatusTags = () =>
    selectedVerificationStatus.map((status) => (
      <Tag
        closable
        onClose={() => removeVerificationStatus(status)}
        key={status}
      >
        {status}
      </Tag>
    ));

  const projectStatusMenu = (
    <Menu>
      <Menu.Item onClick={() => addProjectStatus("Ongoing")}>Ongoing</Menu.Item>
      <Menu.Item onClick={() => addProjectStatus("Not Started")}>
        Not Started
      </Menu.Item>
      <Menu.Item onClick={() => addProjectStatus("Finished")}>
        Finished
      </Menu.Item>
      <Menu.Item onClick={() => addProjectStatus("Terminated")}>
        Terminated
      </Menu.Item>
    </Menu>
  );

  const verificationStatusMenu = (
    <Menu>
      <Menu.Item onClick={() => addVerificationStatus("Verified")}>
        Verified
      </Menu.Item>
      <Menu.Item onClick={() => addVerificationStatus("Unverified")}>
        Unverified
      </Menu.Item>
    </Menu>
  );

  const apiUrl = useApiUrl();
  const TOKEN_KEY = "leyline-auth";
  const token = localStorage.getItem(TOKEN_KEY);
  const marketplaceUploadUrl = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const checkCompanyVerification = async () => {
      try {
        const response = await axios.get(`${apiUrl}/companies/my-company`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (
          !response.data ||
          Object.keys(response.data).length === 0 ||
          response.data.is_verified !== true
        ) {
          setIsModalVisible(true);
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    checkCompanyVerification();
  }, [apiUrl, token]);

  return (
    <Row
      gutter={[16, 16]}
      align="middle"
      justify="space-between"
      className="bg-white p-4 rounded-b-lg"
    >
      <Col xs={24} sm={24} md={4} className="flex items-center">
        <Input.Search
          placeholder="Please enter the project name to search"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
        />
      </Col>
      <Col xs={24} sm={24} md={14} className="flex items-center">
        <Space>
          <Dropdown overlay={projectStatusMenu}>
            <Button>
              {selectedProjectStatus.length > 0
                ? renderProjectStatusTags()
                : "Project Status"}
              <DownOutlined />
            </Button>
          </Dropdown>

          <Dropdown overlay={verificationStatusMenu}>
            <Button>
              {selectedVerificationStatus.length > 0
                ? renderVerificationStatusTags()
                : "Verification Status"}
              <DownOutlined />
            </Button>
          </Dropdown>
          <Checkbox
            checked={isViolated}
            onChange={(e) => setIsViolated(e.target.checked)}
            className="ml-2"
          >
            Violated
          </Checkbox>
        </Space>
      </Col>

      <Col xs={24} sm={24} md={6} className="mt-4 md:mt-0">
        <Space wrap className="flex flex-wrap justify-end gap-2">
          <Link to={ptLinkWithState()}>
            <Button type="default">Producer Tool</Button>
          </Link>
          {!isModalVisible && (
            <Link to="/projects/create-project">
              <Button type="primary">Create Project</Button>
            </Link>
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default SearchHeader;
