import { isImageFile, isVideoFile } from "@/utils/upload";
import { useGetIdentity } from "@refinedev/core";
import { Checkbox, Modal, Tag, Typography } from "antd";
import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useConfigProvider } from "../../contexts";
import { IIdentity } from "../../interfaces";

import { useApiUrl } from "@refinedev/core";
import { Col, Form, FormInstance, Row, message } from "antd";

const { Link } = Typography;

import { UploadFile, UploadProps } from "antd/lib/upload/interface";
import { RcFile } from "antd/lib/upload/interface";

import { PRIVACY_POLICY_URL, USER_AGREEMENT_URL } from "@/constants";
import { modalConfirmSync, padLeftImgUrl } from "@/utils/common";
import {
  formatToLongStyle,
  formatToLongStyleUTC,
  formatToMiddleStyle,
  getTimezoneText,
} from "../../utils/date";
import { showSettleDate } from "./utils";

enum JobPaymentMethod {
  Paperwork = 0,
  Time = 1,
  Negotiable = 2,
  Undefined = 3,
}

type ExtraProps = {
  company_shortname: string;
  is_company_verified: number;
  project_thumbnail_url: string;
  title: string;
  id: string;
};

type ApplicantCardProps = {
  company_logo_url: string;
  job_title: string;
  project_name: string;
  location: string;
  remote_option: string;
  employment_type: string;
  created_at: string;
  id: string;
  status: string;
  rejection_reason: string;
  total_cost: number;
  company_shortname?: string;
  currency: string;

  contract: {
    headcount: number | null;
    start_date: string | null;
    end_date: string | null;
    payment_method: string | null;
    count: number | null;
    count_unit: string | null;
    rate: number | null;
    total_cost: number | null;
    deposit: number | null;
    settle_day: number | null;
    timezone: string;
    currency: string;

    cancelled_at: string | null;
    is_party_a_verified: number | null;
    is_party_b_verified: number | null;
    rejection_reason: string | null;
    contract_file_urls: string[];
    signed_at: string | null;
  } | null;

  // added by combining API results
  extra: ExtraProps;
};

type ModalProps = {
  applied: ApplicantCardProps;
  open: boolean;
  setOpen: (x: boolean) => void;
  refreshList: () => void;
};

export const JobContractApplicantModal = ({
  applied,
  open,
  setOpen,
  refreshList,
}: ModalProps) => {
  const { data: user } = useGetIdentity<IIdentity>();
  const apiUrl = useApiUrl();
  const TOKEN_KEY = "leyline-auth";
  const token = localStorage.getItem(TOKEN_KEY);

  const [paymentMethod, setPaymentMethod] = useState(
    JobPaymentMethod.Paperwork,
  );
  const [calculatedPrice, setCalculatedPrice] = useState("0.00");

  const [testFileList, setTestFileList] = useState<UploadFile[]>([]);

  const [loading, setLoading] = useState(false);

  const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;

  const previewFile = (file: UploadFile, isImage: boolean) => {
    if (!file || !file.originFileObj) return;
    const src = file.url || URL.createObjectURL(file.originFileObj as Blob);
    if (isImage) {
      const imgWindow = window.open(src);
      if (imgWindow) {
        imgWindow.document.write(`<img src="${src}" style="width: 100%;">`);
      }
    } else if (isVideoFile(file)) {
      const videoWindow = window.open(src);
      if (videoWindow) {
        videoWindow.document.write(
          `<video src="${src}" controls style="width: 100%;"></video>`,
        );
      }
    }
  };

  const beforeUpload = (file: RcFile) => {
    const isImage = file.type === "image/jpeg" || file.type === "image/png";
    const isPDF = file.type === "application/pdf";
    const isLt10M = file.size / 1024 / 1024 < 10;

    if (!isImage && !isPDF) {
      message.error("You can only upload JPG/PNG image or PDF file!");
    }
    if (!isLt10M) {
      message.error("File must smaller than 10MB!");
    }

    return (isImage || isPDF) && isLt10M;
  };

  const testUploadProps: UploadProps = {
    action: `${apiUrl}/users/upload`,
    headers: {
      // biome-ignore lint:
      authorization: "Bearer " + localStorage.getItem("leyline-auth"),
    },
    name: "file",
    multiple: false,
    fileList: testFileList,
    listType: "picture",
    maxCount: 1,
    beforeUpload: beforeUpload,
    onChange: ({ fileList }) => {
      const newFileList = fileList
        .filter((file) => {
          const isImage =
            file.type === "image/jpeg" || file.type === "image/png";
          const isPDF = file.type === "application/pdf";
          const isLt10M = file.size || 0 / 1024 / 1024 < 10;

          if (!isImage && !isPDF) {
            return false;
          }
          if (!isLt10M) {
            return false;
          }
          return true;
        })
        .map((file) => {
          return {
            uid: file.uid,
            name: file.name,
            status: file.status,
            url: IMG_PREFIX + file.response?.id,
            type: file.type,
          };
        });
      setTestFileList(newFileList);
    },
    onPreview: async (file) => {
      previewFile(file, isImageFile(file));
    },
  };

  const { mode, setMode } = useConfigProvider();

  // /notfit-reasons
  // API integration - TODO
  const unmatchReasons = [
    "Fee mismatch",
    "Industry mismatch",
    "Skill mismatch",
    "Software mismatch",
    "Work model mismatch",
    "Job type mismatch",
    "Information not accurate",
  ];

  const [clicked, setClicked] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [tagsCount, setTagsCount] = useState(0);
  const [tagSelected, setTagSelected] = useState([] as string[]);

  const tagClasses = "hover: cursor-pointer mt-1 mb-1";
  // @ts-ignore
  const ReasonTag = ({ children, clicked, setClicked }) => {
    return clicked ? (
      <Tag
        color="#2db7f5"
        className={tagClasses}
        onClick={() => {
          setClicked(false);
        }}
      >
        {children}
      </Tag>
    ) : (
      <Tag
        className={tagClasses}
        onClick={() => {
          setClicked(true);
        }}
      >
        {children}
      </Tag>
    );
  };

  const warn = (text: string) => {
    if (mode === "light") {
      toast(text, {
        icon: "⚠️",
      });
    } else {
      toast(text, {
        icon: "⚠️",
        style: {
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  // @ts-ignore
  const tags = [];
  unmatchReasons.forEach((reason, idx) => {
    tags.push(
      // biome-ignore lint:
      <React.Fragment key={idx}>
        <ReasonTag
          clicked={clicked[idx]} // @ts-ignore
          setClicked={(val) => {
            setClicked(
              clicked.map((c, i) => {
                if (i === idx) {
                  return val;
                }
                return c;
              }),
            );
            if (val) {
              setTagsCount(tagsCount + 1);
              setTagSelected((current) => {
                return [...current, reason];
              });
            } else {
              setTagsCount(tagsCount - 1);
              setTagSelected((current) => {
                return current.filter((v) => v !== reason);
              });
            }
          }}
        >
          {reason}
        </ReasonTag>
      </React.Fragment>,
    );
  });

  const formRef = useRef<FormInstance>(null);
  const onFinish = async () => {
    try {
      await formRef.current?.validateFields();
      const operateRes = await modalConfirmSync({
        title: "Please carefully read the contract information and documents.",
        content: "Do you confirm the contract signing?",
        okText: "Yes",
        cancelText: "No",
      });
      if (!operateRes) return;
      setLoading(true);

      const response = await fetch(
        `${apiUrl}/applications/applicant/${applied.id}/sign`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log("response: ", response);
      if (!response.ok) {
        throw new Error("Failed to submit contract");
      }
      message.success(
        "Your contract has been submitted and is waiting for processing. Redirecting back...",
      );
      setOpen(false);
      refreshList();
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    } catch (error: any) {
      const errorPathName = error?.errorFields?.[0]?.name;
      console.error("Error:", error);
      if (errorPathName)
        formRef.current?.scrollToField(errorPathName, {
          block: "center",
        });
      else message.error("Failed to submit contract");
    } finally {
      setLoading(false);
    }
  };

  const [calculateRateUnit, setCalculateRateUnit] = useState("");

  // @ts-ignore
  const recalculatePrice = ({
    // @ts-ignore
    method,
  }: { method: JobPaymentMethod } | null | number) => {
    const headcount = formRef.current?.getFieldValue("numberOfPeople");
    const quantity = formRef.current?.getFieldValue("budget")?.quantity;
    const duration_per_ppl =
      formRef.current?.getFieldValue("budget")?.duration_per_ppl;
    const unit = formRef.current?.getFieldValue("budget")?.unit;
    const unit_price = formRef.current?.getFieldValue("budget")?.unit_price;

    console.log(
      headcount,
      quantity,
      duration_per_ppl,
      unit,
      unit_price,
      method,
      paymentMethod,
    );

    if (method !== undefined) {
      if (method === JobPaymentMethod.Paperwork) {
        formRef.current?.setFieldValue(["budget", "unit"], "");
        setCalculateRateUnit("");
        if (quantity && unit_price) {
          const price = parseInt(quantity) * parseFloat(unit_price);
          setCalculatedPrice(price.toFixed(2));
        } else {
          setCalculatedPrice("0.00");
        }
      } else if (method === JobPaymentMethod.Time) {
        formRef.current?.setFieldValue(["budget", "unit"], "");
        if (unit) {
          setCalculateRateUnit(`person/${unit}`);
        } else {
          setCalculateRateUnit("");
        }
        if (headcount && duration_per_ppl && unit_price) {
          const price =
            parseInt(headcount) *
            parseFloat(duration_per_ppl) *
            parseFloat(unit_price);
          setCalculatedPrice(price.toFixed(2));
        } else {
          setCalculatedPrice("0.00");
        }
      } else {
        setCalculateRateUnit("");
      }
    } else {
      if (paymentMethod === JobPaymentMethod.Paperwork) {
        setCalculateRateUnit("");
        if (quantity && unit_price) {
          const price = parseInt(quantity) * parseFloat(unit_price);
          setCalculatedPrice(price.toFixed(2));
        } else {
          setCalculatedPrice("0.00");
        }
      } else if (paymentMethod === JobPaymentMethod.Time) {
        if (unit) {
          setCalculateRateUnit(`person/${unit}`);
        } else {
          setCalculateRateUnit("");
        }
        if (headcount && duration_per_ppl && unit_price) {
          const price =
            parseInt(headcount) *
            parseFloat(duration_per_ppl) *
            parseFloat(unit_price);
          setCalculatedPrice(price.toFixed(2));
        } else {
          setCalculatedPrice("0.00");
        }
      } else {
        setCalculateRateUnit("");
      }
    }
  };
  // console.log(applied.contract);
  return (
    <>
      <Modal
        title="Contract now"
        open={open}
        width={550}
        onOk={onFinish}
        onCancel={() => {
          setOpen(false);
        }}
      >
        <Row justify="center" className="mt-12">
          <Col span={22}>
            <Form
              ref={formRef}
              name={`contractJobForm-${applied.id}`}
              // onFinish={onFinish}
              layout="vertical"
            >
              <Row gutter={20} className="mb-4">
                <Col span={24}>
                  Project Name: <b>{applied.project_name}</b>
                  <br />
                  Job Name: <b>{applied?.job_title || applied?.extra?.title}</b>
                  <br />
                  Party A:{" "}
                  <b>
                    {applied?.company_shortname ||
                      applied?.extra?.company_shortname}
                  </b>
                  <br />
                  Party B: <b>{user?.name || "(unnamed user)"}</b>
                  <br />
                  Timezone:{" "}
                  <b>{getTimezoneText(applied?.contract?.timezone) ?? "--"}</b>
                  <br />
                </Col>
              </Row>

              <Row gutter={20} className="mb-4">
                <Col span={24}>
                  {applied.contract?.start_date ? (
                    <>
                      {`Working Time Period: ${formatToLongStyleUTC(
                        applied.contract?.start_date,
                      )} to ${formatToLongStyleUTC(
                        applied.contract?.end_date as string,
                      )}`}
                      <br />
                    </>
                  ) : (
                    <React.Fragment />
                  )}
                  {applied.contract?.start_date ? (
                    <>
                      {`Headcount: ${applied.contract?.headcount?.toString()}`}
                      <br />
                    </>
                  ) : (
                    <React.Fragment />
                  )}
                  {applied.contract?.payment_method ? (
                    <>
                      {`Payment Method: ${
                        applied.contract?.payment_method === "Piece-rate"
                          ? `Piece-rate | ${applied.contract?.count} ${
                              applied.contract?.count_unit
                            } | Unit price: ${
                              applied.contract?.currency
                            } ${applied.contract?.rate?.toFixed(2)}`
                          : applied.contract?.payment_method === "Time-based"
                            ? `Time-based | ${applied.contract?.count} ${
                                applied.contract?.count_unit
                              } | Unit price: ${
                                applied.contract?.currency
                              } ${applied.contract?.rate?.toFixed(2)}`
                            : applied.contract?.payment_method === "Negotiable"
                              ? "Negotiable"
                              : "unset"
                      }`}
                      <br />
                    </>
                  ) : (
                    <React.Fragment />
                  )}
                  {applied.contract?.total_cost ? (
                    <>
                      Total cost: {applied.contract?.currency}{" "}
                      {applied.contract?.total_cost?.toFixed(2)}
                      <br />
                    </>
                  ) : (
                    <React.Fragment />
                  )}
                  {/* {applied.contract?.deposit ? (
                    <>
                      Deposit: {applied.contract?.deposit}
                      <Tag className="ml-2" color="green">
                        Paid
                      </Tag>
                      <br />
                    </>
                  ) : (
                    <React.Fragment />
                  )} */}
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={24}>
                  Settlement Date:{" "}
                  {showSettleDate(applied.contract?.settle_day)}
                </Col>
              </Row>
              <Row gutter={20} className="mb-4">
                <Col span={24}>
                  {/* biome-ignore lint/complexity/noExtraBooleanCast: <explanation> */}
                  {!!applied.contract?.contract_file_urls?.length ? (
                    <>
                      {"Contract File: "}
                      {applied.contract?.contract_file_urls.map((url) => (
                        <div key={url}>
                          <Link
                            href={padLeftImgUrl(url)}
                            style={{ color: "#1677ff" }}
                          >
                            View file
                          </Link>
                        </div>
                      ))}
                    </>
                  ) : null}
                  {/* {applied.contract?.signed_at ? (
                    <>
                      {"Signed at: "}
                      {formatToLongStyle(applied.contract?.signed_at)}
                      <br />
                    </>
                  ) : null} */}
                  {applied.contract?.cancelled_at ? (
                    <>
                      Cancelled at:
                      {formatToLongStyle(applied.contract?.cancelled_at)}
                      <br />
                    </>
                  ) : (
                    <React.Fragment />
                  )}
                </Col>
              </Row>

              <div className="bg-orange-200 text-center text-orange-700 py-3">
                <div className="mb-2">
                  Both parties can revoke the contract within 72 hours after
                  signing. Revocation is not permitted after 72 hours.
                </div>
                <div>
                  Please read carefully and confirm the above contract content.
                </div>
              </div>

              <Form.Item
                label={""}
                name="agreement"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              "Please read and agree to the User Service Agreement first.",
                            ),
                          ),
                  },
                ]}
              >
                {/* <Checkbox className="mt-4 text-base">
                  
                </Checkbox> */}
                <Checkbox
                  style={{
                    fontSize: "12px",
                  }}
                >
                  I agree to{" "}
                  <a
                    className="!text-[#1677ff] hover:underline"
                    href={USER_AGREEMENT_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    User Agreement
                  </a>{" "}
                  and{" "}
                  <a
                    className="!text-[#1677ff] hover:underline"
                    href={PRIVACY_POLICY_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </Checkbox>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
