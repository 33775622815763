import { useGlobalStore } from "@/stores";
import { getStaticImgUrl } from "@/utils/common";
import { TOKEN_KEY } from "@/utils/constants";
import { judgeIsCompanyRole } from "@/utils/imUtils";
import { useParsed } from "@refinedev/core";
import { Button, Col, Image, Row } from "antd";
import _ from "lodash";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

const bgImgMap = {
  1: getStaticImgUrl("publicJob/banner_bg1.png") as string,
  2: getStaticImgUrl("publicJob/banner_bg2.png") as string,
  3: getStaticImgUrl("publicJob/banner_bg3.png") as string,
  4: getStaticImgUrl("publicJob/banner_bg4.png") as string,
};
const gradientColorMap = {
  1: "linear-gradient(to right, rgba(193, 143, 106, 0.9),rgba(255, 117, 221, 0.9),rgba(144, 103, 255, 0.9),rgba(189, 200, 219, 0.9))",
  2: "linear-gradient(to right, rgba(127, 127, 127, 1),rgba(254, 252, 253, 1),rgba(211, 211, 211, 1))",
  3: "linear-gradient(to right, rgba(202, 136, 255, 0.9),rgba(96, 226, 255, 0.9),rgba(204, 100, 255, 0.9),rgba(153, 212, 255, 0.9))",
  4: "linear-gradient(to right, rgba(254, 255, 184, 0.9),rgba(117, 238, 255, 0.9),rgba(131, 144, 255, 0.9),rgba(232, 255, 254, 0.9))",
};

const PublicJobsHeader: React.FC = () => {
  const { currentRole } = useGlobalStore((state) => state);
  const isCompanyRole = judgeIsCompanyRole(currentRole);
  const isLoggedIn = !!localStorage.getItem(TOKEN_KEY);
  const navigate = useNavigate();
  const { pathname } = useParsed();

  const bannerBgIndex: 1 | 2 | 3 | 4 = useMemo(
    () => _.sample([1, 2, 3, 4]),
    [],
  );

  const postOrBuild = () => {
    let pageUrl = "";
    if (!isLoggedIn) pageUrl = `/login?to=${pathname + location.search}`;
    else if (isCompanyRole) pageUrl = "/jobs/create?go=1";
    else pageUrl = "/users/edit";
    navigate(pageUrl);
  };

  return (
    <Row className="w-full">
      <Col span={24} className="relative" style={{ height: "400px" }}>
        <Image
          preview={false}
          src={bgImgMap[bannerBgIndex]}
          alt="Career Banner"
          width={"100%"}
          height={"100%"}
          className="w-full h-full object-cover"
        />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
          <h1
            className="text-3xl font-semibold mb-8"
            style={{
              backgroundImage: gradientColorMap[bannerBgIndex],
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            {isCompanyRole && isLoggedIn
              ? "Find Top Talent With Leyline"
              : "Build Your Career With LeyLine"}
          </h1>
          <Button
            type="primary"
            size="large"
            className="rounded-[20px]"
            onClick={postOrBuild}
          >
            {isCompanyRole && isLoggedIn ? "Post Job" : "Build your profile"}
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default PublicJobsHeader;
