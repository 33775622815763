import { UserType } from "@/__generated__/graphql";
import { CustomInput } from "@/components/customComponents/CustomInput";
import { JobPanel } from "@/components/discover/workDetail/JobPanel";
import { useUserData } from "@/hooks";
import { useCheckAuth } from "@/hooks/useCheckAuth";
import { createCommentAPI, useUserProfile } from "@/pages/discover/api";
import {
  ICreateCommentData,
  IJobData,
  ISetReplyCommentInfo,
} from "@/pages/discover/types";
import { useGlobalStore } from "@/stores";
import { getImgUrl } from "@/utils/common";
import { CloseCircleFilled, DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Image, Row, Space, Typography, message } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

interface ICommentInputProps {
  modal?: boolean;
  setReloadState: (state: string) => void;
  getCommentCount: () => void;
  setCommentInputState: (state: boolean) => void;
  artworkId: string;
  setCommentReplyState: (state: boolean) => void;
  commentReplyState: boolean;
  replyCommentInfo: ISetReplyCommentInfo;
}

export const CommentInput: React.FC<ICommentInputProps> = ({
  modal,
  setReloadState,
  getCommentCount,
  setCommentInputState,
  artworkId,
  setCommentReplyState,
  commentReplyState,
  replyCommentInfo,
}) => {
  const { currentRole } = useGlobalStore((state) => state);
  const profile = useUserData();
  const { checkAuth } = useCheckAuth();
  const navigate = useNavigate();
  const [commentContent, setCommentContent] = useState<string>("");
  const [jobPanelVisible, setJobPanelVisible] = useState(false);
  const [selectJob, setSelectJob] = useState<IJobData>();

  const addComment = async () => {
    if (!currentRole) return;
    if (!commentContent) {
      message.error("Please enter a comment");
      return;
    }
    if (modal) {
      await checkAuth();
    } else {
      if (!profile) {
        navigate(`/login?to=/discover/portfolio/${artworkId}`);
        return;
      }
    }

    const requestData: ICreateCommentData = {
      artwork_id: artworkId,
      content: commentContent,
      job_id: selectJob ? selectJob.id : "",
      parent_id: commentReplyState ? replyCommentInfo.parent_id : "",
    };
    const response = await createCommentAPI(requestData, currentRole);
    if (response.id) {
      setCommentInputState(false);
      setCommentReplyState(false);
      setCommentContent("");
      setReloadState(`create_${response.id}`);
      getCommentCount();
    } else {
      message.error("Failed to add comment");
    }
  };

  const onCancel = () => {
    setCommentReplyState(false);
    setCommentInputState(false);
  };

  const onDeleteJob = () => {
    setSelectJob(undefined);
  };
  return (
    <Row className="bg-[#232529] rounded-b-xl py-4 px-6 flex flex-col gap-3">
      {commentReplyState && (
        <Row className="flex flex-row flex-1 w-full justify-between text-[#999]">
          <Typography.Paragraph
            className="!mb-0 text-[#999] w-full flex-1"
            ellipsis={{ rows: 1 }}
          >
            Reply {replyCommentInfo.user_name}: {replyCommentInfo.content}
          </Typography.Paragraph>
          <CloseCircleFilled onClick={() => setCommentReplyState(false)} />
        </Row>
      )}
      <Row>
        <CustomInput
          className="!rounded-full text-base text-white opacity-85 px-4 py-[7px]"
          placeholder="Entering ..."
          maxLength={500}
          value={commentContent}
          onChange={(e) => setCommentContent(e.target.value)}
        />
      </Row>
      {selectJob && (
        <Row className="flex flex-row gap-2 rounded-sm bg-[#343638] p-3 relative">
          <Image
            preview={false}
            width="80px"
            height="60px"
            className="rounded-sm"
            src={
              getImgUrl(selectJob.project_thumbnail_url) ||
              "https://placehold.co/64x64"
            }
          />
          <Col className="flex-1 flex flex-col gap-2.5 justify-center">
            <Typography.Text className="text-white text-opacity-85 font-bold text-base">
              {selectJob.project_name}
            </Typography.Text>
            <Typography.Text className="text-[#606264] text-sm">
              {selectJob.location
                ? `${selectJob.location} / ${selectJob.remote_option} / ${selectJob.employment_type}`
                : `${selectJob.remote_option} / ${selectJob.employment_type}`}
            </Typography.Text>
          </Col>
          <DeleteOutlined
            className="absolute right-2 top-2 text-[#606264]"
            onClick={onDeleteJob}
          />
        </Row>
      )}
      <Row className="flex justify-between gap-3 relative">
        <Col>
          {jobPanelVisible && (
            <div className="absolute bottom-12">
              <JobPanel
                setJobPanelVisible={setJobPanelVisible}
                setSelectJob={setSelectJob}
              />
            </div>
          )}
          {currentRole === UserType.Company && (
            <Space
              className="border-[1px] border-solid border-[#606264] text-[#606264] w-[30px] h-[30px] text-sm rounded-full flex justify-center cursor-pointer"
              onClick={() => setJobPanelVisible(!jobPanelVisible)}
            >
              Job
            </Space>
          )}
        </Col>
        <Col className="flex gap-3">
          <Button
            className="bg-[#232529] border-[1px] border-solid border-[#999] rounded-full text-base px-4 py-[6px] h-auto text-[#999]"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="bg-[#00ADAE] rounded-full border-0 text-base px-4 py-[7px] h-auto text-white"
            onClick={addComment}
          >
            Confirm
          </Button>
        </Col>
      </Row>
    </Row>
  );
};
