import { layoutStyles } from "@/pages/authCore/components/styles";
import { Col, Layout, Row } from "antd";
import { FC } from "react";

interface Props {
  hideForm?: boolean;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  children: any;
}

const authWrapperStyle = {
  backgroundImage: "url('images/loginBg.png')",
  backgroundSize: "cover",
  backgroundRepeat: "repeat-y",
  animation: "scroll-background 20s linear infinite",
};

const AuthLayout: FC<Props> = ({ hideForm, children }) => {
  return (
    <Layout className="h-full" style={{ ...authWrapperStyle, ...layoutStyles }}>
      <Row
        justify="center"
        align={hideForm ? "top" : "middle"}
        className="h-full"
        style={{
          position: "relative",
          padding: "16px 0",
          // minHeight: "100dvh",
          paddingTop: hideForm ? "15dvh" : "16px",
          background: "rgba(0,0,0,.85)",
        }}
      >
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
          <div
            style={{
              width: "25vw",
              height: "25vw",
              position: "absolute",
              left: "-50px",
              top: "-200px",
              borderRadius: "50%",
              background: "rgba(62, 44, 156, 0.30)",
              filter: "blur(150px)",
            }}
          />
          <div
            style={{
              width: "55vw",
              height: "55vw",
              position: "absolute",
              left: "25vw",
              top: "-100px",
              opacity: "0.7",
              borderRadius: "50%",
              background: "rgba(78, 7, 127, 0.5)",
              filter: "blur(150px)",
            }}
          />
          <div
            style={{
              width: "30vw",
              height: "30vw",
              position: "absolute",
              right: "-10vw",
              bottom: "-15vw",
              borderRadius: "50%",
              background: "rgba(125, 43, 121, 0.6)",
              filter: "blur(150px)",
            }}
          />
        </div>
        <Col xs={22}>{children}</Col>
      </Row>
    </Layout>
  );
};

export default AuthLayout;
