import { useRef } from "react";
import { BsBuildings, BsCardImage } from "react-icons/bs";

import { Avatar, Badge, Divider, Space } from "antd";

import { JobInfoProvider } from "@/contexts/jobInfo";
import { padLeftImgUrl } from "@/utils/common";
import moment from "moment";
import { ApplicantRole } from "../../interfaces";
import { ApplyInfoType } from "../../pages/enterprises/utils";
import { formatToLongStyle } from "../../utils/date";
import ApplyJobDrawer from "./components/ApplyJobDrawer";
import { useAppliedButtons } from "./hooks/useAppliedButtons";

type ExtraProps = {
  company_name: string;
  company_shortname: string;
  is_company_verified: number;
  project_thumbnail_url: string;
  id: string;
  title: string;
};

type AppliedCardProps = {
  company_logo_url: string;
  job_title: string;
  job_id: string;
  industry: string;
  introduction: string;
  project_name: string;
  project_id: string;
  location: string;
  remote_option: string;
  employment_type: string;
  created_at: string;
  user_name: string;
  company_id: string;
  company_shortname: string;
  city: string;
  avatar_url: string;
  id: string;
  company_scale: string;
  company_industry: string;
  role: ApplicantRole;
  status: string;
  rejection_reason: string;
  remark?: string;
  total_cost: number;
  company_name: string;
  headcount: number;
  payment_method: string;
  rate: number;
  rate_unit: string;
  count: number;
  count_unit: string;
  answer_url: string;
  answer_link: string;
  is_job_available: number;
  is_company_verified: number;
  project_thumbnail_url: string;
  currency: string;

  contract: {
    headcount: number | null;
    start_date: string | null;
    end_date: string | null;
    payment_method: string | null;
    count: number | null;
    count_unit: string | null;
    rate: number | null;
    total_cost: number | null;
    deposit: number | null;
    settle_day: number | null;
    timezone: string;
    currency: string;

    cancelled_at: string | null;
    is_party_a_verified: number | null;
    is_party_b_verified: number | null;
    rejection_reason: string | null;
    contract_file_urls: string[];
    signed_at: string | null;
  } | null;

  // added by combining API results
  extra: ExtraProps;
  user_id: string;
};

type CardProps = {
  applied: AppliedCardProps;
  refreshList: () => void;
};

interface DrawerRefType {
  open: (info?: ApplyInfoType) => void;
}

const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;

export const JobAppliedCard = ({ applied: x, refreshList }: CardProps) => {
  const editApplyInfoRef = useRef<DrawerRefType>(null);
  const buttonsAndTips = useAppliedButtons(x, refreshList);

  return (
    <>
      <div className="p-3 mb-3 rounded-md bg-white hover:shadow-lg cursor-pointer transition-all">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <span className="relative">
              {x.company_logo_url ? (
                <Avatar
                  shape="square"
                  size={32}
                  src={IMG_PREFIX + x.company_logo_url}
                  alt="Avatar"
                />
              ) : (
                <BsBuildings size={30} color="#aaa" />
              )}
              {x.is_company_verified === 1 && (
                <Badge
                  color="green"
                  count="✓"
                  size="small"
                  offset={[1, 30]}
                  className="absolute right-0 bottom-0"
                />
              )}
            </span>
            <span className=" ml-1">
              {x.company_shortname || x.company_name}
            </span>
          </div>
          <span className="text-gray-400">
            Application time: {formatToLongStyle(x.created_at, "-")}
          </span>
        </div>
        <Divider type="horizontal" className="mt-2 mb-2" />
        <div className="flex mt-2 justify-between">
          <div className="flex">
            {x.project_thumbnail_url ? (
              <img
                alt="job cover"
                className="h-28 w-32 object-cover rounded-lg"
                src={IMG_PREFIX + x.project_thumbnail_url}
              />
            ) : (
              <BsCardImage size={30} color="#aaa" />
            )}

            <div className="ml-3">
              <span className="text-lg text-gray-950">{x.job_title}</span>
              <div>{x.project_name}</div>
              <div className="my-2" />
              <span>
                {x.location ? `${x.location} / ` : ""}
                {x.remote_option} / {x.employment_type}
              </span>

              <div className="my-2" />
              <span>
                Published date: {moment(x.created_at).format("YYYY-MM-DD")}
              </span>
            </div>
          </div>
          <div className="text-gray-400 w-2/5">
            <div>Headcount: {x.headcount}</div>
            <div>
              Payment Method:{" "}
              {x.payment_method === "Negotiable" ? (
                x.payment_method
              ) : (
                <Space
                  size={0}
                  split={
                    <Divider className="border-gray-400" type="vertical" />
                  }
                >
                  <span>{x.payment_method}</span>
                  <span>
                    {x.count} {x.count_unit}
                  </span>
                  <span>
                    Unit price: {x.currency || "USD"}
                    &nbsp;{x.rate?.toFixed(2)} {x.rate_unit}
                  </span>
                </Space>
              )}
            </div>
            <div>
              Total: {x.currency || "USD"}
              &nbsp;{x.total_cost?.toFixed(2)}
            </div>
            {x.answer_url ? (
              <a
                href={padLeftImgUrl(x.answer_url)}
                target="_blank"
                rel="noopener noreferrer"
              >
                View test
              </a>
            ) : null}
          </div>
        </div>
        <div>{buttonsAndTips}</div>
      </div>
      <JobInfoProvider jobData={x}>
        <ApplyJobDrawer ref={editApplyInfoRef} refresh={refreshList} />
      </JobInfoProvider>
    </>
  );
};
