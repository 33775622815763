import React from "react";

import { Avatar, Card, Col, Divider, Row, Typography } from "antd";

import { padLeftImgUrl } from "@/utils/common";
import { formatToLongStyle } from "../../utils/date";
import { useApplicantButtons } from "./hooks/useApplicantButtons";

const { Title, Text, Paragraph } = Typography;

type ExtraProps = {
  company_shortname: string;
  is_company_verified: number;
  project_thumbnail_url: string;
  id: string;
  title: string;
};

export type ApplicantInfo = {
  project_name: string;
  project_id: string;
  location: string;
  created_at: string;
  industry: string;
  introduction: string;
  remote_option: string;
  employment_type: string;
  city: string;
  avatar_url: string;
  user_name: string;
  id: string;
  rejection_reason: string;
  remark?: string;
  job_id: string;
  job_title: string;
  company_id: string;
  company_name: string;
  company_shortname: string;
  company_logo_url: string;
  company_scale: string;
  company_industry: string;
  role: string;
  is_job_available: number;
  currency: string;

  contract: {
    headcount: number | null;
    start_date: string | null;
    end_date: string | null;
    payment_method: string | null;
    count: number | null;
    count_unit: string | null;
    rate: number | null;
    total_cost: number | null;
    deposit: number | null;
    settle_day: number | null;
    timezone: string;
    currency: string;

    cancelled_at: string | null;
    is_party_a_verified: number | null;
    is_party_b_verified: number | null;
    rejection_reason: string | null;
    contract_file_urls: string[];
    signed_at: string | null;
  } | null;

  status: string;

  answer_url: string;
  answer_link: string;

  headcount: number;
  payment_method: string;
  count: number;
  count_unit: string;
  rate: number;
  rate_unit: string;
  total_cost: number;

  // added by combining API results
  extra: ExtraProps;
  user_id: string;
};

type CardProps = {
  applicant: ApplicantInfo;
  askUnmatch: () => void;
  refreshList: () => void;
};

const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;

export const ArtistApplicantCard = ({
  applicant: x,
  refreshList,
}: // askUnmatch
CardProps) => {
  const buttonsAndModals = useApplicantButtons(x, refreshList);

  return (
    <>
      <Card hoverable className="flex mb-2" bodyStyle={{ width: "100%" }}>
        <Row gutter={20}>
          <Col span={14}>
            <Title level={5}>
              {x?.job_title || x.extra?.title}
              <Text type="secondary" className="ml-2">
                {x.project_name}
              </Text>
            </Title>
          </Col>
          <Col span={10} className="text-right">
            <Text type="secondary">
              Application time: {formatToLongStyle(x.created_at, "-")}
            </Text>
          </Col>
        </Row>

        <Divider type="horizontal" className="my-2" />

        <Row gutter={20}>
          <Col span={14}>
            <div className="flex mt-2">
              <Avatar
                size={64}
                src={`${IMG_PREFIX}${
                  x.role === "Company" ? x.company_logo_url : x.avatar_url
                }`}
                alt="Avatar"
              />
              <div className="ml-3">
                <Title level={5}>
                  {x.role === "Company"
                    ? x.company_shortname || x.company_name || "unknown"
                    : x.user_name}
                </Title>
                <Text type="secondary">
                  {x.role === "Company" ? (
                    `${x.company_industry}*${x.company_scale}`
                  ) : (
                    <>
                      {" "}
                      {x.industry} *
                      {x.introduction || "No self-introduction filled"}
                      <br />
                    </>
                  )}
                </Text>
              </div>
            </div>
            <Paragraph
              type="secondary"
              ellipsis={{ tooltip: x.city }}
              className="ml-[68px]"
            >
              {`📍${x.city || "No city filled"}`}
            </Paragraph>
          </Col>
          <Col span={10}>
            <Text type="secondary">
              Headcount: {x.headcount}
              <br />
              Payment Method: {x.payment_method}
              {x.payment_method === "Negotiable" ? (
                <React.Fragment />
              ) : (
                <>
                  {" | "}
                  {x.count} {x.count_unit} | Unit price: {x.currency || "USD"}{" "}
                  {x.rate?.toFixed(2)} {x.rate_unit}
                </>
              )}
              <br />
              Total: {x.currency || "USD"} {x.total_cost?.toFixed(2)}
              <br />
            </Text>
            {x.answer_url || x.answer_link ? (
              <a
                href={padLeftImgUrl(x.answer_url || x.answer_link)}
                target="_blank"
                rel="noopener noreferrer"
              >
                View test
              </a>
            ) : (
              <Text>No answer submitted</Text>
            )}
          </Col>
        </Row>

        <Divider type="horizontal" className="my-2" />
        <div>{buttonsAndModals}</div>
      </Card>
    </>
  );
};
