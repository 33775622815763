import { UserType } from "@/__generated__/graphql";
import { ArtistCollections } from "@/components/discover/artistDetail/ArtistCollections";
import { ArtistFollow } from "@/components/discover/artistDetail/ArtistFollow";
import { ArtistIntroduction } from "@/components/discover/artistDetail/ArtistIntroduction";
import { ArtistLikes } from "@/components/discover/artistDetail/ArtistLikes";
import { ArtistMenu } from "@/components/discover/artistDetail/ArtistMenu";
import { ArtistResume } from "@/components/discover/artistDetail/ArtistResume";
import { AuthorPortfolio } from "@/components/discover/artistDetail/AuthorPortfolio";
import { CompanyIntroduction } from "@/components/discover/artistDetail/CompanyIntroduction";
import { CompanyJobs } from "@/components/discover/artistDetail/CompanyJobs";
import { IIdentity, IUserInfo } from "@/interfaces";
import { getCompanyInfoAPI, getUserInfoAPI } from "@/pages/discover/api";
import { ICompany } from "@/pages/discover/types";
import { getLogoUrl } from "@/utils/helper";
import { SEOProps } from "@/utils/type";
import { useGetIdentity } from "@refinedev/core";
import { Col } from "antd";
import { FC, useCallback, useEffect, useRef, useState } from "react";

interface Props {
  className?: string;
  hideBack?: boolean;
  artistId?: string;
  userRole: string;
  userType: string;
  onClose?: () => void;
  onSeoDataLoad?: (data: SEOProps) => void;
}

const ArtistPortfolioCore: FC<Props> = ({
  className,
  hideBack,
  userRole,
  userType,
  artistId: artistIdOuter,
  onClose,
  onSeoDataLoad,
}) => {
  const [authorTabState, setAuthorTabState] = useState<string>("portfolio");
  const [userInfo, setUserInfo] = useState<IUserInfo | null>(null);
  const [companyInfo, setCompanyInfo] = useState<ICompany | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { data: user } = useGetIdentity<IIdentity>();
  const userId = user?.id || "";
  const artistId = userType === "myself" ? userId : artistIdOuter || "";
  const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const getUserInfo = useCallback(async () => {
    if (userRole === UserType.Company) {
      const response = await getCompanyInfoAPI(artistId);
      setCompanyInfo(response);
      if (onSeoDataLoad && response) {
        onSeoDataLoad({
          title: `LeyLine - ${response.name}, ${
            response.description || response.industry
          }`,
          description: response.description || "Company Homepage",
          keywords: `${response.name},studio homepage,CG studio,art studio,Animation studio,Game trailers,vfx studio,game studio`,
          image: IMG_PREFIX + response.logo_url || getLogoUrl(),
          ogTitle: `LeyLine - ${response.name}`,
          ogDescription: response.description || "Company Homepage",
        });
      }
    } else if (userRole === UserType.Individual) {
      const response = await getUserInfoAPI(artistId);
      setUserInfo(response);
      console.log("getUserInfo", response);
      if (onSeoDataLoad && response) {
        onSeoDataLoad({
          title: `LeyLine - ${response.profile.user_name}, ${
            response.profile.introduction || response.profile.industry
          }`,
          description:
            response.profile.introduction ||
            response.profile.summary ||
            "Personal Homepage",
          keywords: `${response.profile.user_name},homepage,artist,studio,personal center,art freelancer`,
          image: IMG_PREFIX + response.profile.avatar_url,
          ogTitle: `LeyLine - ${response.profile.user_name}`,
          ogDescription:
            response.profile.introduction ||
            response.profile.summary ||
            "Personal Homepage",
        });
      }
    }
  }, [artistId, userRole]);

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  // biome-ignore lint:
  useEffect(() => {
    setAuthorTabState("portfolio");
  }, [artistIdOuter]);

  const renderContent = () => {
    switch (authorTabState) {
      case "portfolio":
        return (
          <AuthorPortfolio
            containerRef={containerRef}
            author_id={artistId}
            userId={userId}
            user_role={userRole}
          />
        );
      case "resume":
        return (
          userInfo && (
            <ArtistResume
              user_role={userRole}
              artistUserInfo={userInfo}
              userId={userId}
              authorId={artistId}
            />
          )
        );
      case "introduction":
        return (
          companyInfo && (
            <CompanyIntroduction
              user_role={userRole}
              companyInfo={companyInfo}
              authorId={artistId}
              userId={userId}
            />
          )
        );
      case "jobs":
        return (
          <CompanyJobs
            containerRef={containerRef}
            userId={userId}
            authorId={artistId}
            user_role={userRole}
          />
        );
      case "likes":
        return (
          <ArtistLikes
            containerRef={containerRef}
            author_id={artistId}
            user_role={userRole}
          />
        );
      case "collections":
        return (
          <ArtistCollections
            containerRef={containerRef}
            author_id={artistId}
            userId={userId}
            user_role={userRole}
          />
        );
      case "follow":
        return (
          <ArtistFollow
            containerRef={containerRef}
            author_id={artistId}
            user_role={userRole}
          />
        );
      default:
        return null;
    }
  };

  const artistProfile =
    userRole === UserType.Company ? companyInfo : userInfo?.profile;

  return (
    <Col
      ref={containerRef}
      className={`py-5 px-10 2xl:px-36 lg:px-24 sm:px-12 w-full h-full overflow-auto ${className}`}
    >
      <div className="w-full">
        {artistProfile && (
          <ArtistIntroduction
            hideBack={hideBack}
            artistUserInfo={artistProfile}
            artistId={artistId}
            user_role={userRole}
            refresh={getUserInfo}
            onClose={onClose}
          />
        )}
      </div>
      <div className="w-full">
        <ArtistMenu
          setAuthorTab={setAuthorTabState}
          authorTab={authorTabState}
          user_role={userRole}
        />
      </div>
      <div className="flex flex-col w-full">{renderContent()}</div>
    </Col>
  );
};

export default ArtistPortfolioCore;
