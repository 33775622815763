import { Button, Modal } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

interface CreateDepartmentModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const CreateDepartmentModal: React.FC<CreateDepartmentModalProps> = ({
  isVisible,
  onClose,
}) => {
  const navigate = useNavigate();

  const handleConfirm = () => {
    navigate("/projects/edit-project");
    onClose();
  };

  return (
    <Modal
      title="Create a Department"
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleConfirm}>
          Edit Project
        </Button>,
      ]}
    >
      <p>
        If you want to post a job, please first create a department. Click here
        to go to the project edit page.
      </p>
    </Modal>
  );
};

export default CreateDepartmentModal;
