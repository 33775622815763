import { Image } from "antd";
import { FC } from "react";

interface Props {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  children: any;
  title?: string;
  description?: string;
  hideLogo?: boolean;
}

const LeyLineLogo = (
  <Image
    rootClassName="mx-auto block !w-[100px] !h-[100px] mb-1"
    src="images/L-logo-3.png"
    preview={false}
  />
);

const ContainerContent: FC<Props> = ({
  title,
  description,
  hideLogo = false,
  children,
}) => {
  return (
    <div className="p-[60px] pt-8 bg-[#333538]/[.6] border-[1px] border-solid border-white/[.4] rounded-xl backdrop-blur">
      {!hideLogo && LeyLineLogo}
      <div className="text-white text-2xl text-center mb-1">
        {title || "Welcome LeyLine"}
      </div>
      <div className="text-[#999] text-base text-center mb-7">
        {description || "The one-stop-shop platform for artists"}
      </div>
      {children}
    </div>
  );
};

export default ContainerContent;
