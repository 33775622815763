import {
  RegisterFormTypes,
  RegisterPageProps,
  useActiveAuthProvider,
  useCreate,
  useLink,
  useParsed,
  useRouterType,
  useUpdate,
} from "@refinedev/core";
import { useRegister, useRouterContext, useTranslate } from "@refinedev/core";
import {
  Button,
  Card,
  CardProps,
  Checkbox,
  Col,
  Divider,
  Form,
  FormProps,
  Image,
  Input,
  Layout,
  LayoutProps,
  Row,
  Typography,
  notification,
  theme,
} from "antd";
import React, { useEffect, useState } from "react";

import { CustomButton, CustomInput } from "@/components/customComponents";
import { PRIVACY_POLICY_URL, USER_AGREEMENT_URL } from "@/constants";
import ContainerContent from "@/pages/authCore/components/containerContent";
import AuthLayout from "@/pages/authCore/layout";
import { passwordValidator } from "@/pages/authCore/utils";
import {
  KeyOutlined,
  LockOutlined,
  MailOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { ThemedTitleV2 } from "@refinedev/antd";
import { useSearchParams } from "react-router-dom";
import { axiosInstance } from "../../../../utils/http";
import {
  bodyStyles,
  containerStyles,
  headStyles,
  layoutStyles,
  subTitleStyles,
  titleStyles,
} from "../styles";
import styles from "./index.module.css";

const { Link } = Typography;

type RegisterProps = RegisterPageProps<LayoutProps, CardProps, FormProps>;

type stepType = "register" | "otp";
type EmailVerifyFormTypes = {
  user_id?: string;
  otp?: string;
};
interface MRegisterFormTypes extends RegisterFormTypes, EmailVerifyFormTypes {
  step?: stepType;
  invitation_code?: string;
  redirectPath?: string;
}

const LeyLineLogo = (
  <Image
    rootClassName="mx-auto block !w-[100px] !h-[100px] mb-1"
    src="images/L-logo-3.png"
    preview={false}
  />
);

/**
 * **refine** has register page form which is served on `/register` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/antd-auth-page/#register} for more details.
 */
export const RegisterPage: React.FC<RegisterProps> = ({
  providers,
  loginLink,
  wrapperProps,
  contentProps,
  renderContent,
  formProps,
  title,
  hideForm,
}) => {
  const [current, setCurrent] = useState<stepType>("register");
  const needVerifyEmail = current === "otp" ? true : false;
  const [newUserId, setNewUserId] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");

  const { token } = theme.useToken();
  const [form] = Form.useForm<MRegisterFormTypes>();
  const [otpForm] = Form.useForm<EmailVerifyFormTypes>();
  const translate = useTranslate();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();
  const [searchParams] = useSearchParams();

  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;

  const authProvider = useActiveAuthProvider();

  const { mutate: register, isLoading } = useRegister<MRegisterFormTypes>({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });

  const { mutate: registerOtp, isLoading: isOTPLoading } =
    useRegister<MRegisterFormTypes>({
      v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
    });

  const [cooldown, setCooldown] = useState(0);
  const [isSendingCode, setIsSendingCode] = useState(false); // Added state for loading effect
  const inviCodeQuery = searchParams.get("inviCode");
  const { params } = useParsed();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (cooldown > 0) {
      timer = setTimeout(() => setCooldown(cooldown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [cooldown]);

  useEffect(() => {
    console.log("current", current);
    if (needVerifyEmail) {
      otpForm.setFieldValue("user_id", newUserId);
      otpForm.setFieldValue("email", newUserEmail);
      sendVerificationCode();
    }
  }, [current, newUserId, newUserEmail, needVerifyEmail, otpForm]);

  useEffect(() => {
    form.setFieldValue(
      "invitation_code",
      inviCodeQuery ? inviCodeQuery?.toUpperCase() : undefined,
    );
  }, [inviCodeQuery, form]);

  const sendVerificationCode = async () => {
    if (cooldown === 0) {
      setIsSendingCode(true);
      await axiosInstance.post(
        `/auth/${newUserId}/generate_code?email=${encodeURIComponent(
          newUserEmail,
        )}`,
      );
      notification.success({
        message: "Verification Code Sent",
        description: "Please check your email for the code",
      });
      setCooldown(60);
      setIsSendingCode(false);
    }
  };
  const PageTitle =
    title === false ? null : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "32px",
          fontSize: "20px",
        }}
      >
        {title ?? <ThemedTitleV2 collapsed={false} />}
      </div>
    );

  const CardTitle = (
    <>
      <Typography.Title
        level={3}
        style={{
          color: token.colorPrimaryTextHover,
          ...titleStyles,
        }}
      >
        {needVerifyEmail ? "Verify your email" : " Welcome to LeyLine"}
      </Typography.Title>
      <Typography.Title
        level={5}
        style={{
          color: token.colorPrimaryTextHover,
          ...subTitleStyles,
        }}
      >
        The one-stop-shop platform for artists
      </Typography.Title>
    </>
  );

  const renderProviders = () => {
    if (providers && providers.length > 0) {
      return (
        <>
          {providers.map((provider) => {
            return (
              <Button
                key={provider.name}
                type="primary"
                icon={provider.icon}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: "8px",
                }}
                // onClick={() =>
                //   register({
                //     providerName: provider.name,
                //   })
                // }
              >
                {provider.label}
              </Button>
            );
          })}
          {!hideForm && (
            <Divider>
              <Typography.Text
                style={{
                  color: token.colorTextLabel,
                }}
              >
                {translate("pages.login.divider", "or")}
              </Typography.Text>
            </Divider>
          )}
        </>
      );
    }
    return null;
  };

  const CardContent = (
    <ContainerContent>
      {renderProviders()}
      {!hideForm && (
        <Form<RegisterFormTypes>
          layout="vertical"
          form={form}
          validateTrigger="onBlur"
          className={styles.register}
          onFinish={(values) =>
            register(
              {
                ...values,
                step: "register",
                redirectPath: params?.redirectPath,
              },
              {
                onSuccess: (data, variables, context) => {
                  // @ts-ignore
                  if (data?.success === true) {
                    setCurrent("otp");
                    // @ts-ignore
                    setNewUserId(data?.user_id as string);
                    setNewUserEmail(variables.email as string);
                  }
                },
                onError: (error, variables, context) => {
                  console.error("error", error);
                },
              },
            )
          }
          requiredMark={false}
          {...formProps}
        >
          <Form.Item name="user_name" rules={[{ required: true }]}>
            <CustomInput
              roundRadius
              size="large"
              maxLength={100}
              placeholder="The name displayed in LeyLine"
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="email"
            // label={translate("pages.register.email", "Email")}
            rules={[
              { required: true },
              {
                type: "email",
                message: translate(
                  "pages.register.errors.validEmail",
                  "Invalid email address",
                ),
              },
            ]}
          >
            <CustomInput
              roundRadius
              size="large"
              placeholder={translate("pages.register.fields.email", "Email")}
              allowClear
              autoComplete={"off"}
              prefix={<MailOutlined style={{ color: "#999" }} />}
            />
          </Form.Item>
          <Form.Item
            className="password"
            name="password"
            // label={translate("pages.register.fields.password", "Password")}
            extra={
              <span className="leading-4">
                At least 8+ characters with at least one digit, one uppercase,
                one lowercase, and one special character.
              </span>
            }
            rules={[
              {
                required: true,
                validator: passwordValidator,
              },
            ]}
          >
            <CustomInput.Password
              roundRadius
              placeholder="Password"
              size="large"
              allowClear
              autoComplete={"off"}
              prefix={<LockOutlined style={{ color: "#999" }} />}
            />
          </Form.Item>

          <Form.Item
            name="confirm"
            // label={translate(
            //   "pages.register.fields.confirmPassword",
            //   "Confirm Password",
            // )}
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: translate(
                  "pages.register.errors.confirmPassword",
                  "Please confirm your password!",
                ),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      translate(
                        "pages.register.errors.passwordMatch",
                        "The two passwords do not match",
                      ),
                    ),
                  );
                },
              }),
            ]}
          >
            <CustomInput.Password
              roundRadius
              size="large"
              placeholder="Confirm Password"
              allowClear
              autoComplete={"off"}
              prefix={<LockOutlined style={{ color: "#999" }} />}
            />
          </Form.Item>
          <Form.Item name="invitation_code">
            <CustomInput
              roundRadius
              size="large"
              maxLength={16}
              placeholder="Invitation Code(optional)"
              prefix={<UserAddOutlined className="!text-[#999]" />}
              allowClear
              onChange={(e) => {
                form.setFieldValue(
                  "invitation_code",
                  e.target.value?.toUpperCase(),
                );
              }}
            />
          </Form.Item>
          <Form.Item
            name="allowTerm"
            valuePropName="checked"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("You must accept before registering"),
                  );
                },
              }),
            ]}
          >
            <Checkbox className="text-[#999] pl-4 leading-4">
              By creating an account, you agree to our{" "}
              <a
                className="hover:underline"
                style={{ color: token.colorPrimary }}
                href={USER_AGREEMENT_URL}
                target="_blank"
                rel="noreferrer"
              >
                User Agreement
              </a>{" "}
              and{" "}
              <a
                className="hover:underline"
                style={{ color: token.colorPrimary }}
                href={PRIVACY_POLICY_URL}
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </Checkbox>
          </Form.Item>
          <Form.Item
            className="text-center"
            style={{
              marginBottom: 0,
            }}
          >
            <Button
              className="!w-[200px]"
              type="primary"
              size="large"
              htmlType="submit"
              loading={isLoading}
            >
              {translate("pages.register.buttons.submit", "Sign up")}
            </Button>
          </Form.Item>
          <div className="mt-3 text-center">
            {loginLink ?? (
              <Typography.Text
                style={{
                  fontSize: 12,
                  marginLeft: "auto",
                }}
              >
                {translate(
                  "pages.login.buttons.haveAccount",
                  "Have an account?",
                )}{" "}
                <ActiveLink
                  style={{
                    fontWeight: "bold",
                    color: token.colorPrimaryTextHover,
                  }}
                  to={`/login${location.search}`}
                >
                  {translate("pages.login.signin", "Sign in")}
                </ActiveLink>
              </Typography.Text>
            )}
          </div>
        </Form>
      )}
      {hideForm && loginLink !== false && (
        <div
          style={{
            marginTop: hideForm ? 16 : 8,
          }}
        >
          <Typography.Text
            style={{
              fontSize: 12,
            }}
          >
            {translate("pages.login.buttons.haveAccount", "Have an account?")}{" "}
            <ActiveLink
              style={{
                fontWeight: "bold",
                color: token.colorPrimaryTextHover,
              }}
              to={`/login${location.search}`}
            >
              {translate("pages.login.signin", "Sign in")}
            </ActiveLink>
          </Typography.Text>
        </div>
      )}
    </ContainerContent>
  );

  const VerifyEmailCardContent = (
    <ContainerContent title="Verify your email">
      {renderProviders()}
      <Form<EmailVerifyFormTypes>
        layout="vertical"
        form={otpForm}
        onFinish={(values) => {
          registerOtp({
            ...values,
            step: "otp",
            redirectPath: params?.redirectPath,
          });
        }}
        requiredMark={false}
        // initialValues={{ user_id: params?.user_id, email: params?.email }}
      >
        <Form.Item
          name="user_id"
          label={"user_id"}
          rules={[{ required: true }]}
          hidden
        >
          <CustomInput size="large" placeholder={"user_id"} disabled />
        </Form.Item>
        {/* <Form.Item
          name="email"
          // label={translate("pages.register.email", "Email")}
          rules={[
            { required: true },
            {
              type: "email",
              message: translate(
                "pages.register.errors.validEmail",
                "Invalid email address",
              ),
            },
          ]}
        >
          <CustomInput
            size="large"
            placeholder={translate("pages.register.fields.email", "Email")}
            allowClear
            disabled
          />
        </Form.Item> */}
        <div className="text-white pl-5 mb-7">Email: {newUserEmail}</div>
        <Form.Item
          name="otp"
          rules={[
            {
              required: true,
              len: 6,
            },
          ]}
        >
          <CustomInput
            roundRadius
            placeholder="Verification Code"
            size="large"
            allowClear
            prefix={<KeyOutlined className="!text-[#999]" />}
            suffix={
              <CustomButton
                type="primary"
                ghost
                loading={isSendingCode}
                onClick={sendVerificationCode}
              >
                {cooldown > 0 ? `${cooldown}s` : "Send the code"}
              </CustomButton>
            }
          />
        </Form.Item>
        <Form.Item className="text-center mb-0 mt-10">
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={isOTPLoading}
          >
            Confirm
          </Button>
        </Form.Item>
      </Form>
    </ContainerContent>
  );

  const registerCardContent = () => {
    if (renderContent) {
      return renderContent(CardContent, PageTitle);
    }
    return (
      <>
        {PageTitle}
        {CardContent}
      </>
    );
  };
  const verifyEmailCardContent = () => {
    if (renderContent) {
      return renderContent(VerifyEmailCardContent, PageTitle);
    }
    return (
      <>
        {PageTitle}
        {VerifyEmailCardContent}
      </>
    );
  };

  return (
    <AuthLayout hideForm={hideForm}>
      <div style={needVerifyEmail ? { display: "none" } : {}}>
        {registerCardContent()}
      </div>
      <div style={needVerifyEmail ? {} : { display: "none" }}>
        {verifyEmailCardContent()}
      </div>
    </AuthLayout>
  );
};
