import {
  LoginFormTypes,
  LoginPageProps,
  useActiveAuthProvider,
  useLink,
  useLogin,
  useParsed,
  useRouterContext,
  useRouterType,
  useTranslate,
} from "@refinedev/core";
import {
  Button,
  Card,
  CardProps,
  Checkbox,
  Col,
  Divider,
  Form,
  FormProps,
  Input,
  Layout,
  LayoutProps,
  Row,
  Typography,
  theme,
} from "antd";
import React, { useEffect } from "react";

import { CustomInput } from "@/components/customComponents";
import ContainerContent from "@/pages/authCore/components/containerContent";
import AuthLayout from "@/pages/authCore/layout";
import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import { ThemedTitleV2 } from "@refinedev/antd";
import { subTitleStyles, titleStyles } from "../styles";

type LoginProps = LoginPageProps<LayoutProps, CardProps, FormProps>;

/**
 * **refine** has a default login page form which is served on `/login` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/antd-auth-page/#login} for more details.
 */
export const LoginPage: React.FC<LoginProps> = ({
  providers,
  registerLink,
  forgotPasswordLink,
  rememberMe,
  contentProps,
  wrapperProps,
  renderContent,
  formProps,
  title,
  hideForm,
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm<LoginFormTypes>();
  const translate = useTranslate();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();
  const { params } = useParsed();

  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;

  const authProvider = useActiveAuthProvider();
  const { mutate: login, isLoading } = useLogin<LoginFormTypes>({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });

  useEffect(() => {
    // The variable `prevPagePath` stores the previous route.
    console.log("prevPagePath", sessionStorage.getItem("prevPagePath"));
  }, []);

  const PageTitle =
    title === false ? null : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "32px",
          fontSize: "20px",
        }}
      >
        {title ?? <ThemedTitleV2 collapsed={false} />}
      </div>
    );

  const CardTitle = (
    <>
      <Typography.Title
        level={3}
        style={{
          color: token.colorPrimaryTextHover,
          ...titleStyles,
        }}
      >
        Welcome to LeyLine
      </Typography.Title>
      <Typography.Title
        level={5}
        style={{
          color: token.colorPrimaryTextHover,
          ...subTitleStyles,
        }}
      >
        The one-stop-shop platform for artists
      </Typography.Title>
    </>
  );

  const renderProviders = () => {
    if (providers && providers.length > 0) {
      return (
        <>
          {providers.map((provider) => {
            return (
              <Button
                key={provider.name}
                type="default"
                block
                icon={provider.icon}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: "8px",
                }}
                onClick={() =>
                  login({
                    providerName: provider.name,
                  })
                }
              >
                {provider.label}
              </Button>
            );
          })}
          {!hideForm && (
            <Divider>
              <Typography.Text
                style={{
                  color: token.colorTextLabel,
                }}
              >
                {translate("pages.login.divider", "or")}
              </Typography.Text>
            </Divider>
          )}
        </>
      );
    }
    return null;
  };

  const CardContent = (
    <ContainerContent>
      {renderProviders()}
      {!hideForm && (
        <Form<LoginFormTypes>
          layout="vertical"
          form={form}
          onFinish={(values) => {
            login({ ...values, redirectPath: params?.redirectPath });
          }}
          requiredMark={false}
          initialValues={{
            remember: false,
          }}
          {...formProps}
        >
          <Form.Item
            name="email"
            // label={translate("pages.login.fields.email", "Email")}
            rules={[
              { required: true },
              {
                type: "email",
                message: translate(
                  "pages.login.errors.validEmail",
                  "Invalid email address",
                ),
              },
            ]}
          >
            <CustomInput
              roundRadius
              size="large"
              placeholder={"Email"}
              prefix={<MailOutlined style={{ color: "#999" }} />}
            />
          </Form.Item>
          <Form.Item
            name="password"
            // label={translate("pages.login.fields.password", "Password")}
            rules={[{ required: true }]}
          >
            <CustomInput.Password
              roundRadius
              type="password"
              autoComplete="current-password"
              placeholder="Password"
              size="large"
              prefix={<LockOutlined style={{ color: "#999" }} />}
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "24px",
            }}
          >
            {rememberMe ?? (
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {translate("pages.login.buttons.rememberMe", "Remember me")}
                </Checkbox>
              </Form.Item>
            )}
            {forgotPasswordLink ?? (
              <ActiveLink
                style={{
                  color: token.colorPrimaryTextHover,
                  fontSize: "12px",
                  marginLeft: "auto",
                }}
                to={`/forgot-password${location.search}`}
              >
                {translate(
                  "pages.login.buttons.forgotPassword",
                  "Forgot password?",
                )}
              </ActiveLink>
            )}
          </div>
          {!hideForm && (
            <Form.Item className="text-center">
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={isLoading}
                className="w-[200px] mb-9"
              >
                {translate("pages.login.signin", "Sign in")}
              </Button>
            </Form.Item>
          )}
        </Form>
      )}
      <Divider className="!border-white/[.2] !text-white/[.5]">Or</Divider>
      {registerLink ?? (
        <div
          className="text-center"
          style={{
            marginTop: hideForm ? 16 : 8,
          }}
        >
          <Typography.Text style={{ fontSize: 12 }}>
            {translate(
              "pages.login.buttons.noAccount",
              "Don’t have an account?",
            )}{" "}
            <ActiveLink
              to={`/register${location.search}`}
              style={{
                fontWeight: "bold",
                color: token.colorPrimaryTextHover,
              }}
            >
              {translate("pages.login.signup", "Sign up")}
            </ActiveLink>
          </Typography.Text>
        </div>
      )}
    </ContainerContent>
  );

  return (
    <AuthLayout hideForm={hideForm}>
      {renderContent ? (
        renderContent(CardContent, PageTitle)
      ) : (
        <>
          {PageTitle}
          {CardContent}
        </>
      )}
    </AuthLayout>
  );
};
