// @ts-ignore
import { decodeText } from "@leyline/chat-uikit-react/dist/cjs/components/TUIMessage/utils/decodeText";
import {
  useGetIdentity,
  useGetLocale,
  useIsExistAuthentication,
  useList,
  useOne,
  useParsed,
  useSetLocale,
  useTranslate,
} from "@refinedev/core";
import TencentCloudChat, { Message } from "@tencentcloud/chat";
import { TUILogin } from "@tencentcloud/tui-core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Avatar,
  Badge,
  Button,
  Col,
  Grid,
  Image,
  Layout as AntdLayout,
  MenuProps,
  Row,
  Select,
  Space,
  Typography,
  notification,
  theme,
} from "antd";

import { useTranslation } from "react-i18next";

// import { Title } from "@/components/Title";
import { LeyLineIcon } from "@/components/icons";
import { useGlobalStore } from "@/stores";
import eventBus from "@/utils/eventBus";
import { judgeIsCompanyRole } from "@/utils/imUtils";
import { set } from "lodash";
import { UserType, useImportImUserMutation } from "../../__generated__/graphql";
import { useHasCompanyRole } from "../../hooks";
import {
  ICourier,
  IIdentity,
  IOrder,
  IProfile,
  IStore,
  userIdentityBody,
} from "../../interfaces";
import { getImgUrl, padLeftImgUrl } from "../../utils/common";
import { ptLink, ptLinkWithState } from "../../utils/helper";
import { HeaderTitle } from "./styled";
import { handleDefaultRole } from "./utils";

const { Header: AntdHeader } = AntdLayout;
const { useToken } = theme;
const { Text } = Typography;
const { TOTAL_UNREAD_MESSAGE_COUNT_UPDATED, MESSAGE_RECEIVED } =
  TencentCloudChat.EVENT;

interface IOptionGroup {
  value: string;
  label: string | React.ReactNode;
}

interface IOptions {
  label: string | React.ReactNode;
  options: IOptionGroup[];
}

const VITE_RECON_WEB_BASE = import.meta.env.VITE_RECON_WEB_BASE;
const VITE_LANDING_WEB_BASE = import.meta.env.VITE_LANDING_WEB_BASE;
const VITE_MKTP_WEB_BASE = import.meta.env.VITE_MKTP_WEB_BASE;
const IM_SDK_APP_ID = import.meta.env.VITE_IM_SDK_APP_ID;
const isDEV = import.meta.env.DEV;

const RoleItemComp = ({ label, count }: { label: string; count: number }) => {
  return (
    <span>
      {label}
      <Badge size="small" className="ml-2" count={count} />
    </span>
  );
};

export const HeaderLite: React.FC = () => {
  const { token } = useToken();
  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const navigate = useNavigate();
  const changeLanguage = useSetLocale();
  const { data: user } = useGetIdentity<IIdentity>();
  const { data, isLoading } = useOne({
    resource: "users",
    id: "profile",
    queryOptions: {
      enabled: !!user?.id,
    },
  });

  const { currentRole } = useGlobalStore((state) => state);
  const isCompanyRole = judgeIsCompanyRole(currentRole);
  const [importImUser] = useImportImUserMutation();
  const { companyInfo } = useHasCompanyRole(isCompanyRole);
  const profile = data?.data.profile as IProfile;

  const isExistAuthentication = useIsExistAuthentication();
  const t = useTranslate();

  const { pathname } = useParsed();
  const isChatPath = pathname?.includes("/enterprises/chat");

  const ptUrl = ptLink("projects");

  const navigationPublic = [
    { name: "Home", path: "/", linkPath: `${VITE_MKTP_WEB_BASE}` },
    {
      name: "Jobs",
      path: "/jobs",
      linkPath: `${VITE_MKTP_WEB_BASE}jobs`,
    },
    {
      name: "Producer Tool",
      path: "/producer-tool",
      linkPath: ptUrl,
    },
    ...(isDEV
      ? [
          {
            name: "AI",
            path: "/ai",
            linkPath: `${VITE_LANDING_WEB_BASE}ai`,
          },
        ]
      : []),
    {
      name: "Secure Cloud Infrastructure",
      path: `${VITE_LANDING_WEB_BASE}secure-cloud-infrastructure`,
    },
  ];

  const isIndexPage =
    pathname === "/" || pathname === "/enterprises/public-jobs";
  const navList = navigationPublic;
  // const navList = navigationPublic;
  const renderTitle = (title: string) => (
    <HeaderTitle>
      <Text style={{ fontSize: "16px" }}>{title}</Text>
      <Link to={`/${title.toLowerCase()}`}>{t("search.more")}</Link>
    </HeaderTitle>
  );

  const renderItem = (title: string, imageUrl: string, link: string) => ({
    value: title,
    label: (
      <Link to={link} style={{ display: "flex", alignItems: "center" }}>
        <Avatar size={64} src={imageUrl} style={{ minWidth: "64px" }} />
        <Text style={{ marginLeft: "16px" }}>{title}</Text>
      </Link>
    ),
  });

  const onClickNotification = useCallback(
    (msg: Message) => {
      navigate("/enterprises/chat", {
        state: { opponentImId: msg.from },
      });
    },
    [navigate],
  );

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const handleMsg = useCallback((msgPayload: any) => {
    const atomMsgList = decodeText(msgPayload);
    return (
      <>
        {/* biome-ignore lint/suspicious/noExplicitAny: <explanation> */}
        {atomMsgList.map((item: any) => {
          switch (item.name) {
            case "img":
              return <Image src={item.src} width={20} height={20} />;
            default:
              return <span>{item.text}</span>;
          }
        })}
      </>
    );
  }, []);

  return (
    <AntdHeader
      style={{
        backgroundColor: token.colorBgElevated,
        padding: "0 24px",
        position: "sticky",
        top: 0,
        zIndex: 1,
      }}
    >
      <Row align="middle" justify={"space-between"}>
        <Col sm={6}>
          <Space size="middle">
            <div
              style={{
                // height: "72px",
                width: "110px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link to={"/projects"}>
                {/* <Image src="/images/L-logo-1.png" preview={false} width={"70%"} /> */}
                <LeyLineIcon
                  style={{
                    color: token.colorTextHeading,
                    width: "100%",
                    verticalAlign: "middle",
                  }}
                />
              </Link>
            </div>
            {navList.map((item, index) => (
              <Button
                type="link"
                href={item.linkPath}
                key={item.name}
                style={{ opacity: "0.78", fontWeight: 500 }}
              >
                <Typography.Text> {item.name}</Typography.Text>
              </Button>
            ))}
          </Space>
        </Col>

        <Col>
          <Space size="middle" align="center">
            {profile?.user_name ? (
              <>
                <Text
                  ellipsis
                  strong
                  style={{
                    display: "flex",
                  }}
                >
                  {profile?.user_name}
                </Text>
                <Link to="/users/home">
                  <Avatar
                    size="large"
                    src={
                      getImgUrl(profile?.avatar_url) ||
                      "https://placehold.co/64x64"
                    }
                    alt={profile?.user_name}
                  />
                </Link>
              </>
            ) : (
              <>
                <Button
                  type="default"
                  onClick={() =>
                    navigate(`/login?to=${pathname + location.search}`, {
                      replace: false,
                    })
                  }
                >
                  Sign In
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    navigate(`/register?to=${pathname + location.search}`, {
                      replace: false,
                    })
                  }
                >
                  Sign Up
                </Button>
              </>
            )}
          </Space>
        </Col>
      </Row>
    </AntdHeader>
  );
};
