import { getLogoUrl } from "@/utils/helper";
import { SEOProps } from "@/utils/type";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const defaultKeywords = "leyline, leyline pro, leylinepro";

const globalSEOConfig: SEOProps = {
  title: "LeyLine - The one-stop-shop platform for studios",
  description:
    "Achieve over 40% savings on premium CG video production using our AI-driven producer tool, seamlessly integrated with a global marketplace. Optimize your creative process and enhance your studio's production efficiency.",
  keywords:
    "make game trailers, make animations video,game ads,cgi video maker,games promo video,in-game cinematics,animation,animation demo reel,visual effects,VFX, breakdown,CGI,SFX, portfolio,3D Artist,2D Artist,short film,concept art,motion graphics,CG comp, AI, AI models, 3D generation, Image generation, storyboard generation",
  ogTitle: "LeyLine",
  ogDescription:
    "Unlock over 40% cost-savings on high-end CG videos with our AI-powered producer tool, integrated with a global marketplace. Streamline your creative workflow and elevate your studio's production efficiency.",
  image: getLogoUrl(),
};

const seoConfig: { [key: string]: SEOProps } = {
  "/": {
    title:
      "LeyLine - Discover studio and artist portfolios, plus tutorials on creating high-quality content",
    description:
      "Showcase your art on our artist-focused platform and learn from a thriving community. Whether you're a seasoned professional or an emerging talent, easily upload and promote your digital art, videos, and more. Connect with artists worldwide and unlock opportunities for exhibitions and gallery showcases.",
    keywords:
      "Art,Artwork,Visual art,Digital art,3D art,Artist portfolio,Art community,Art sharing platform,Art marketplace,Online art gallery,Showcase artwork,Share artwork online,Art platform,Digital portfolio, CG platform, CG community, CG marketplace, CG showcase",
    ogTitle: "LeyLine - Portfolio and Tutorial Discover",
    ogDescription:
      "Present your creations on our artist-oriented platform and engage with a dynamic community. Whether you’re an experienced professional or an up-and-coming talent, effortlessly share and highlight your digital art, videos, and more. Network with artists globally and access opportunities for exhibitions and gallery presentations.",
    image: getLogoUrl(),
  },
  "/enterprises/public-jobs": {
    title: "LeyLine - Explore new business and career opportunities",
    description:
      "LeyLine connects artists with a variety of job and project opportunities, from freelance gigs to contractor positions. Explore diverse art careers such as illustration, 3D modeling, animation, and more, and discover the perfect role to advance your creative career.",
    keywords:
      "artist jobs,creative jobs,art careers,freelance artist work,artist job platform, artistic opportunities, outsourcing CG, outsourcing animation",
    ogTitle: "LeyLine - Explore new business and career opportunities",
    ogDescription:
      "LeyLine links artists with a range of employment and project opportunities, from outsourcing to contract roles. Explore various art professions, including illustration, 3D modeling, animation, and beyond, and find the ideal tasks to further your creative career.",
    image: getLogoUrl(),
  },
  "/producer-tool": {
    title: "LeyLine - Seamless real-time cloud collaboration from anywhere",
    description: "Effortless real-time cloud collaboration from anywhere",
    keywords:
      "Producer Tool,animation pipeline, VFX pipeline, project management, games pipeline, assets management tool, artists management tool, shot management tool, video editing tool. AI, AI models, 3D generation, Image generation, storyboard generation",
    ogTitle: "LeyLine - Cloud production pipeline",
    ogDescription: "Seamless real-time cloud collaboration from anywhere",
    image: getLogoUrl(),
  },
  "/ai": {
    title:
      "LeyLine - Bring your visions to life faster, easier, and more affordably with cutting-edge AI models",
    description:
      "Transform your ideas more quickly, easily, and cost-effectively using advanced AI models",
    keywords:
      "AI tools,scrpit,breakdowns,AV Script,screenplay,Storyboard,Concept,Modelling,Animatic,Dubbing, AI, AI models, 3D generation, Image generation, storyboard generation",
    ogTitle: "LeyLine - AI",
    ogDescription:
      "Bring your visions to life faster, easier, and more affordably with cutting-edge AI models.",
    image: getLogoUrl(),
  },
};

const SEO: React.FC<SEOProps> = (props) => {
  const location = useLocation();
  const path = location.pathname;

  const defaultConfig = seoConfig[path] || globalSEOConfig;

  const {
    title = defaultConfig.title,
    description = defaultConfig.description,
    keywords = defaultConfig.keywords,
    ogTitle = defaultConfig.ogTitle || title,
    ogDescription = defaultConfig.ogDescription || description,
    image = defaultConfig.image || getLogoUrl(),
  } = props;

  const combinedKeywords = `${defaultKeywords}, ${props.keywords || keywords}`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={combinedKeywords} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content="website" />
    </Helmet>
  );
};

export default SEO;
