import { useApiUrl, useGetIdentity } from "@refinedev/core";
import { Button, Checkbox, Col, Modal, Row, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import { IIdentity } from "../../interfaces";

const { Link } = Typography;

import moment from "moment";

import { padLeftImgUrl } from "@/utils/common";
import {
  formatRemainingTime,
  formatToLongStyle,
  formatToLongStyleUTC,
  getTimezoneText,
} from "../../utils/date";
import { showSettleDate } from "./utils";

type ExtraProps = {
  company_shortname: string;
  is_company_verified: number;
  project_thumbnail_url: string;
  title: string;
};

type ApplicationCardProps = {
  project_name: string;
  location: string;
  created_at: string;
  industry: string;
  introduction: string;
  city: string;
  avatar_url: string;
  user_name: string;
  id: string;
  rejection_reason: string;
  company_shortname: string;
  job_title: string;
  currency: string;

  contract: {
    headcount: number | null;
    start_date: string | null;
    end_date: string | null;
    payment_method: string | null;
    count: number | null;
    count_unit: string | null;
    rate: number | null;
    total_cost: number | null;
    deposit: number | null;
    settle_day: number | null;
    timezone: string;

    cancelled_at: string | null;
    is_party_a_verified: number | null;
    is_party_b_verified: number | null;
    rejection_reason: string | null;
    contract_file_urls: string[];
    signed_at: string | null;
    currency: string;
  } | null;

  status: string;

  answer_url: string;
  answer_link: string;

  headcount: number;
  payment_method: string;
  count: number;
  count_unit: string;
  rate: number;
  rate_unit: string;
  total_cost: number;

  // added by combining API results
  extra: ExtraProps;
};

type ModalProps = {
  curRole: "partyA" | "partyB";
  application: ApplicationCardProps;
  open: boolean;
  refreshList: () => void;
  setOpen: (x: boolean) => void;
};

export const JobContractDetailModal = ({
  curRole,
  application,
  open,
  refreshList,
  setOpen,
}: ModalProps) => {
  const apiUrl = useApiUrl();
  const { data: user } = useGetIdentity<IIdentity>();
  const [agree, setAgree] = useState(false);

  // When opened, get the latest contract data
  useEffect(() => {
    if (open) refreshList();
  }, [open, refreshList]);

  const fnRevokeSign = () => {
    Modal.confirm({
      title: "Whether to cancel the current signed position?",
      async onOk() {
        const loadingMessage = message.loading("Revoking, please wait...", 0);
        try {
          const res = await fetch(
            `${apiUrl}/applications/${application.id}/party-${
              curRole === "partyA" ? "a" : "b"
            }/cancel`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("leyline-auth")}`,
              },
            },
          );
          if (res.ok) {
            setOpen(false);
            refreshList();
            message.success("Revoked the current signed position");
          } else {
            message.error(
              "Failed to revoke the current signed position - try again later",
            );
          }
        } catch (error) {
          console.error("Error revoking", error);
          message.error(
            "Failed to revoke the current signed position - try again later",
          );
        } finally {
          loadingMessage();
        }
      },
    });
  };

  return (
    <>
      <Modal
        title="Contract detail"
        width={600}
        open={open}
        onOk={() => {
          setOpen(false);
        }}
        onCancel={() => {
          setOpen(false);
        }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            {/* <CancelBtn /> */}
            <OkBtn />
          </>
        )}
      >
        {open && (
          <>
            {application.status === "B_SIGNED" && (
              <>
                <div className="text-right">
                  You can revoke the current signing within{" "}
                  {formatRemainingTime(
                    application.contract?.signed_at as string,
                    3,
                  )}
                </div>
                <div className="flex justify-end items-center">
                  <Button type="link" onClick={fnRevokeSign}>
                    Revoke immediately
                  </Button>
                </div>
              </>
            )}
            <Row justify="center" className="mt-12">
              <Col span={20}>
                <Row gutter={20} className="mb-4">
                  <Col span={24}>
                    Project Name: <b>{application.project_name}</b>
                    <br />
                    Job Name:{" "}
                    <b>{application?.job_title || application?.extra?.title}</b>
                    <br />
                    Party A:{" "}
                    <b>
                      {application?.company_shortname ||
                        application?.extra?.company_shortname}
                    </b>
                    <br />
                    Party B:{" "}
                    <b>
                      {application.user_name || user?.name || "(unnamed user)"}
                    </b>
                    <br />
                    Timezone:{" "}
                    <b>
                      {getTimezoneText(application?.contract?.timezone) ?? "--"}
                    </b>
                    <br />
                  </Col>
                </Row>

                <Row gutter={20} className="mb-4">
                  <Col span={24}>
                    {application.contract?.start_date ? (
                      <>
                        {`Working Time period: ${formatToLongStyleUTC(
                          application.contract?.start_date,
                        )} to ${formatToLongStyleUTC(
                          application.contract?.end_date as string,
                        )}`}
                        <br />
                      </>
                    ) : null}
                    {application.contract?.start_date ? (
                      <>
                        {`Headcount: ${application.contract?.headcount?.toString()}`}
                        <br />
                      </>
                    ) : null}
                    {application.contract?.payment_method ? (
                      <>
                        {`Payment Method: ${
                          application.contract?.payment_method === "Piece-rate"
                            ? `Piece-rate | ${application.contract?.count} ${
                                application.contract?.count_unit
                              } | Unit price: ${
                                application.contract?.currency
                              } ${application.contract?.rate?.toFixed(2)}`
                            : application.contract?.payment_method ===
                                "Time-based"
                              ? `Time-based | ${application.contract?.count} ${
                                  application.contract?.count_unit
                                } | Unit price: ${
                                  application.contract?.currency
                                } ${application.contract?.rate?.toFixed(2)}`
                              : application.contract?.payment_method ===
                                  "Negotiable"
                                ? "Negotiable"
                                : "unset"
                        }`}
                        <br />
                      </>
                    ) : null}
                    {application.contract?.total_cost ? (
                      <>
                        Total cost: {application.contract?.currency}{" "}
                        {application.contract?.total_cost?.toFixed(2)}
                        <br />
                      </>
                    ) : null}
                    {/* {application.contract?.deposit ? (
                    <>
                      Deposit: {application.contract?.deposit} (Paid)
                      <br />
                    </>
                  ) : null} */}
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span={24}>
                    Settlement Date:{" "}
                    {showSettleDate(application.contract?.settle_day)}
                  </Col>
                </Row>
                <Row gutter={20} className="mb-4">
                  <Col span={24}>
                    {application.contract?.contract_file_urls?.length ? (
                      <>
                        {"Contract File: "}
                        {application.contract.contract_file_urls.map((url) => (
                          <>
                            <Link
                              onClick={() =>
                                window.open(
                                  padLeftImgUrl(url as string),
                                  "_blank",
                                )
                              }
                            >
                              View file
                            </Link>
                            <br />
                          </>
                        ))}
                      </>
                    ) : null}
                    {application.status === "B_SIGNED" ? (
                      <>
                        {"Signed at: "}
                        {formatToLongStyle(
                          application.contract?.signed_at as string,
                        )}
                        <br />
                      </>
                    ) : null}
                    {application.contract?.cancelled_at ? (
                      <>
                        Cancelled at:
                        {formatToLongStyle(application.contract?.cancelled_at)}
                        <br />
                      </>
                    ) : null}
                    {curRole === "partyB" &&
                      application.status === "A_SIGNED" && (
                        <div className="text-center mt-7">
                          <Checkbox
                            value={agree}
                            onChange={(e) => setAgree(e.target.value)}
                          >
                            I have read and agreed to the 《User Service
                            Agreement》
                          </Checkbox>
                        </div>
                      )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Modal>
    </>
  );
};
