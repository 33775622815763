import { UserType } from "@/__generated__/graphql";
import TencentCloudChat, { ChatSDK, Message } from "@tencentcloud/chat";
import { TUIChatService } from "@tencentcloud/chat-uikit-engine";
import { Modal } from "antd";
import { NavigateFunction } from "react-router-dom";

export const setConversationCustomData = async (
  chat: ChatSDK | null,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  setRemoteCustomDataMutation: any,
  opponentImId: string,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  customData: any,
) => {
  const customDataStr = JSON.stringify(customData);
  const remoteCustomDataStr = JSON.stringify({
    jInfo: {
      ...customData?.jInfo,
      role: customData?.jInfo?.role === 1 ? 2 : 1,
    },
  });
  console.log("customData size:", new Blob([customDataStr]).size);
  const res = await chat?.getConversationProfile(`C2C${opponentImId}`);
  if (res?.code === 0) {
    const {
      conversation: { customData: oldCustomData },
    } = res.data;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    let oldCustomDataObj: any;
    try {
      oldCustomDataObj = JSON.parse(oldCustomData);
    } catch (error) {
      console.warn("parse oldCustomData fail", oldCustomData, error);
    }
    if (oldCustomDataObj?.jInfo?.id !== customData.jInfo.id) {
      const myProfile = await chat?.getMyProfile();
      const options = {
        to: opponentImId,
        conversationType: TencentCloudChat.TYPES.CONV_C2C,
        payload: {
          // text: `I am very interested in the ${customData.jInfo.titl}. Can I chat with you?`,
          text: `${myProfile?.data?.nick} initiated communication regarding the ${customData.jInfo.titl}.`,
        },
      };
      // @ts-ignore
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      TUIChatService.sendTextMessage(options).then((res: any) => {
        const { message } = res.data;
        setRemoteCustomDataMutation?.({
          variables: {
            setCustomDataIn: {
              jobId: customData?.jInfo?.id,
              optType: 2, // custom type
              customData: remoteCustomDataStr,
              fromAccount: opponentImId,
              toAccount: chat?.getLoginUser(),
            },
          },
        });
      });
    }
  } else {
    Modal.warning({
      title: "Something went wrong, please refresh the page.",
      onOk() {
        window.location.reload();
      },
    });
    console.log(chat);
    console.error(res);
    return Promise.reject("Something went wrong.");
  }
  chat
    ?.setConversationCustomData({
      conversationIDList: [`C2C${opponentImId}`],
      customData: customDataStr,
    })
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    ?.then((imResponse: any) => {
      console.log("setConversationCustomData success", imResponse);
    })
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    ?.catch((imError: any) => {
      console.warn("setConversationCustomData error:", imError);
    });
};

export enum ShortUserType {
  COMPANY = "Company",
  APPLICANTCOMPANY = "Company",
  INDIVIDUAL = "Individual",
  APPLICANTINDIVIDUAL = "Individual",
}

export enum RoleTypeValue {
  INDIVIDUAL = 1,
  APPLICANTINDIVIDUAL = 1,
  COMPANY = 2,
  APPLICANTCOMPANY = 2,
}

export const judgeIsCompanyRole = (role: UserType | null) =>
  [UserType.Applicantcompany, UserType.Company].includes(role as UserType);

export const judgeIsIndividualRole = (role: UserType | null) =>
  [UserType.Applicantindividual, UserType.Individual].includes(
    role as UserType,
  );

export const jumpChatPage = async (
  applicant: {
    user_id: string;
    job_id: string;
    job_title: string;
    project_name: string;
  },
  chatClient: ChatSDK | null,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  setRemoteCustomDataMutation: any,
  navigate: NavigateFunction,
  role: number,
) => {
  const opponentImId = `${applicant?.user_id}_company`;
  await setConversationCustomData(
    chatClient,
    setRemoteCustomDataMutation,
    opponentImId,
    {
      jInfo: {
        id: applicant?.job_id,
        titl: applicant?.job_title,
        pName: applicant?.project_name,
        role,
      },
    },
  );
  navigate("/enterprises/chat", {
    state: {
      opponentImId: `${applicant?.user_id}_company`,
      activeTab: role,
    },
  });
};

export const jumpChatPageByMsg = async (
  msg: Message,
  navigate: NavigateFunction,
  chat: ChatSDK | null,
) => {
  let activeTab = 1;
  if (["leyline_bot_party_a", "leyline_bot_party_b"].includes(msg.from)) {
    activeTab = msg.from === "leyline_bot_party_a" ? 2 : 1;
  } else {
    const res = await chat?.getConversationProfile(msg.conversationID);
    if (res?.code === 0) {
      const {
        conversation: { customData },
      } = res.data;
      try {
        const customDataObj = JSON.parse(customData);
        activeTab = customDataObj.jInfo.role ?? 1;
      } catch (error) {
        console.error("parse oldCustomData fail", customData, error);
      }
    }
  }
  navigate("/enterprises/chat", {
    state: { opponentImId: msg.from, activeTab },
  });
};
