import themeConfig from "@/constants/themeConfig";
import ArtistPortfolioCore from "@/pages/discover/ArtistPortfolioCore";
import { ConfigProvider, Drawer } from "antd";

interface Props {
  open: boolean;
  userId: string;
  userRole: string;
  onCancel: () => void;
}

const ResumeDetailDrawer = ({ open, userId, userRole, onCancel }: Props) => {
  return (
    <ConfigProvider theme={themeConfig}>
      <Drawer
        open={open}
        title="View Resume"
        rootClassName="top-20"
        onClose={onCancel}
        placement="right"
        className="!bg-[#1D1F22]"
        closable
        width={"84%"}
        maskClosable={true}
      >
        {open && (
          <ArtistPortfolioCore
            className="!px-3 !pt-0"
            artistId={userId}
            userRole={userRole}
            userType="artist"
            hideBack
          />
        )}
      </Drawer>
    </ConfigProvider>
  );
};

export default ResumeDetailDrawer;
