import { NoDataIcon } from "@/components/customComponents/CustomIcons";
import { JobItem } from "@/components/discover/artistDetail/JobItem";
import { getCompanyJobsAPI } from "@/pages/discover/api";
import { IRequestCompanyJobsData } from "@/pages/discover/types";
import { useGlobalStore } from "@/stores";
import Icon, { SettingOutlined } from "@ant-design/icons";
import { useInfiniteScroll } from "ahooks";
import { Button, Col, Row, Typography } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface ICompanyJobsProps {
  containerRef: React.RefObject<HTMLDivElement>;
  authorId: string;
  userId: string;
  user_role: string;
}

export const CompanyJobs: React.FC<ICompanyJobsProps> = ({
  containerRef,
  authorId,
  userId,
  user_role,
}) => {
  const { currentRole } = useGlobalStore((state) => state);
  const navigate = useNavigate();
  const [requestData, setRequestData] = useState<IRequestCompanyJobsData>({
    page: 1,
    size: 10,
    target_id: authorId,
  });

  const getCompanyJobs = async (page: number) => {
    const response = await getCompanyJobsAPI({ ...requestData, page });
    return {
      list: response.list,
      nextPage: response.page < response.pages ? response.page + 1 : undefined,
    };
  };

  const {
    data: infiniteData,
    loadMore,
    loading,
    reload,
  } = useInfiniteScroll((d) => getCompanyJobs(d ? d.nextPage : 1), {
    target: containerRef,
    isNoMore: (d) => !d?.nextPage,
    // reloadDeps: [currentRole],
  });

  return (
    <div className="flex flex-col">
      {authorId === userId && user_role === currentRole && (
        <div className="-mt-3 mb-3.5 text-right">
          <Button
            type="primary"
            shape="round"
            className="py-2 px-6 h-full text-sm"
            onClick={() => navigate("/jobs")}
          >
            <SettingOutlined />
            <span>Job Management</span>
          </Button>
        </div>
      )}
      <Col className="mt-6">
        {infiniteData?.list?.length === 0 ? (
          <Col className="flex flex-col justify-center items-center w-full h-full">
            <Icon className="text-[200px]" component={NoDataIcon} />
            <Typography.Text className="text-[#686868] text-base">
              No Jobs
            </Typography.Text>
          </Col>
        ) : (
          <Row className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-5">
            {infiniteData?.list?.map(
              (job) =>
                job.is_enabled && (
                  <JobItem
                    key={job.id}
                    job={job}
                    authorId={authorId}
                    userId={userId}
                  />
                ),
            )}
          </Row>
        )}
      </Col>
    </div>
  );
};
