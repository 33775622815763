import SEO from "@/components/seo";
import JobDetail from "@/pages/enterprises/components/JobDetail";
import { getLogoUrl } from "@/utils/helper";
import { IResourceComponentsProps, useParsed } from "@refinedev/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const PublicJobDetails: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();
  const { id } = useParsed();

  const [jobData, setJobData] = useState<{
    title: string;
    company_name: string;
    description: string;
    project_cover: string;
  } | null>(null);

  return (
    <>
      {/* SEO */}
      {jobData && (
        <SEO
          title={`LeyLine - ${jobData.title}, posted by ${jobData.company_name}`}
          keywords={`artist jobs,creative jobs,art careers,freelance artist work,artist job platform,artistic opportunities,outsourcing CG,outsourcing animation,${jobData.title},${jobData.company_name}`}
          description={jobData.description || "Skills and Requirement"}
          ogTitle={`LeyLine - ${jobData.title}, posted by ${jobData.company_name}`}
          ogDescription={jobData.description || "Skills and Requirement"}
          image={jobData.project_cover || getLogoUrl()}
        />
      )}

      {/* Main page content */}
      <div className="p-6 h-full">
        <JobDetail
          id={id as string}
          onClose={() => {
            const prevPagePath = sessionStorage.getItem("prevPagePath");
            navigate(prevPagePath ? prevPagePath : "/enterprises/public-jobs");
          }}
          onJobDataLoad={(data: {
            title: string;
            company_name: string;
            description: string;
            project_cover: string;
          }) => {
            setJobData(data);
          }}
        />
      </div>
    </>
  );
};
