import Icon, {
  EditOutlined,
  EnvironmentFilled,
  HomeOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import {
  useGetIdentity,
  useLink,
  useMenu,
  useParsed,
  useRefineContext,
  useRouterContext,
  useRouterType,
  useTitle,
} from "@refinedev/core";
import {
  Avatar,
  Col,
  Divider,
  Grid,
  Row,
  Space,
  Tooltip,
  Typography,
  message,
  theme,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { UserType } from "@/__generated__/graphql";
import {
  CompanyIcon,
  DefaultUserIcon,
  UserFollowers,
} from "@/components/customComponents/CustomIcons";
import { UserFollowing } from "@/components/customComponents/CustomIcons";
import { CustomMenus } from "@/components/customComponents/CustomMenus";
import { updateLocalRole } from "@/components/header/utils";
import getMenuData, {
  isPersonalCenterPage,
  openKeys,
  renderFooterMenu,
} from "@/components/sider/menuData";
import { useHasCompanyRole } from "@/hooks";
import { IIdentity, IProfile } from "@/interfaces";
import { getFollowCountAPI, useUserProfile } from "@/pages/discover/api";
import { useGlobalStore } from "@/stores";
import { getImgUrl } from "@/utils/common";
import { formatNumber } from "@/utils/formatNumber";
import { judgeIsCompanyRole } from "@/utils/imUtils";
import { ThemedTitleV2, useThemedLayoutContext } from "@refinedev/antd";
import { useNavigate } from "react-router-dom";
import styles from "../header/index.module.css";
import { RefineThemedLayoutV2SiderProps } from "./types";

export const Sider: React.FC<RefineThemedLayoutV2SiderProps> = ({
  Title: TitleFromProps,
  render,
  meta,
  fixed,
  activeItemDisabled = false,
}) => {
  const { token } = theme.useToken();
  const { setMobileSiderOpen } = useThemedLayoutContext();
  const { data: user } = useGetIdentity<IIdentity>();
  const [selectedKey, setSelectedKey] = useState<string[]>([]);
  const { data } = useUserProfile(user?.id || "");
  const [followCount, setFollowCount] = useState({
    follower_count: 0,
    followee_count: 0,
  });
  const { currentRole, updateCurrentRole } = useGlobalStore((state) => state);
  const isCompanyRole = judgeIsCompanyRole(currentRole);
  const { companyInfo, hasEmployerView } = useHasCompanyRole(isCompanyRole);

  const profile = data?.data.profile as IProfile;
  const { resource, action, id, pathname } = useParsed();
  const isIndexPage = pathname === "/" || pathname?.startsWith("/enterprises");
  const navigate = useNavigate();

  const routerType = useRouterType();
  const NewLink = useLink();
  const { Link: LegacyLink } = useRouterContext();
  const Link = routerType === "legacy" ? LegacyLink : NewLink;
  const TitleFromContext = useTitle();
  const { menuItems, defaultOpenKeys } = useMenu({ meta });

  const breakpoint = Grid.useBreakpoint();
  const { hasDashboard } = useRefineContext();

  const getFollowCount = useCallback(async () => {
    if (!currentRole) return;
    const response = await getFollowCountAPI(user?.id ?? "", currentRole);
    setFollowCount(response);
  }, [user, currentRole]);

  useEffect(() => {
    getFollowCount();
  }, [getFollowCount]);
  useEffect(() => {
    pathname && setSelectedKey([pathname]);
  }, [pathname]);

  const handledMenuData = useMemo(() => {
    return [
      ...getMenuData(isCompanyRole),
      ...renderFooterMenu(isCompanyRole),
    ].filter((menu) => menu?.key !== "/logout");
  }, [isCompanyRole]);

  const isMobile =
    isIndexPage ||
    (typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg);

  const RenderToTitle = TitleFromProps ?? TitleFromContext ?? ThemedTitleV2;

  const switchRole = () => {
    if (!isCompanyRole && !hasEmployerView)
      // return message.warning("You have not passed enterprise certification.");
      return navigate("/companies/show");
    const newRole = isCompanyRole ? UserType.Individual : UserType.Company;
    isPersonalCenterPage(pathname) && navigate("/homepage");
    updateCurrentRole(newRole);
    updateLocalRole(newRole);
  };
  // const goToHome = () => {
  //   navigate(isCompanyRole ? "/companies" : "/users/profile");
  // };
  // const editInfo = () => {
  //   navigate(isCompanyRole ? "/companies/edit-homepage" : "/users/edit");
  // };

  // const dashboard = <></>;
  // const items = renderMenuTreeView(menuData, selectedKey);

  // const renderSider = () => {
  //   if (render) {
  //     // return render({
  //     //   dashboard,
  //     //   items,
  //     //   logout,
  //     //   collapsed: siderCollapsed,
  //     // });
  //   }
  //   return (
  //     <>
  //       {dashboard}
  //       {items}
  //     </>
  //   );
  // };

  const renderMenu = () => {
    return (
      <CustomMenus
        selectedKeys={selectedKey}
        openKeys={openKeys}
        defaultOpenKeys={defaultOpenKeys}
        mode="inline"
        onClick={() => {
          setMobileSiderOpen(false);
        }}
        onSelect={(e) => {
          if (e.key === "switchRole") {
            switchRole();
          } else {
            setSelectedKey(e.selectedKeys);
            navigate(e.key);
          }
        }}
        className="bg-transparent"
        items={handledMenuData}
      />
    );
  };

  return (
    <Row className="border-none h-full flex flex-col person-sider w-full">
      <div className="p-4 relative w-[inherit]">
        <Col
          className="flex flex-col px-10 py-6 gap-2.5 pb-8 items-center rounded-lg"
          style={{
            background: "linear-gradient(180deg, #232529 0%, #000 99.52%)",
          }}
        >
          <div className="relative">
            <div className="relative">
              <Avatar
                size={60}
                className={`object-cover ${
                  isCompanyRole && "border-[2px] border-solid border-[#03B3B4]"
                }`}
                src={
                  getImgUrl(
                    isCompanyRole
                      ? companyInfo?.extended_info?.logo_url
                      : profile?.avatar_url,
                  ) || <DefaultUserIcon />
                }
              />
              {isCompanyRole && (
                <Space className="absolute z-10 left-1/2 top-[-15%] transform -translate-x-1/2">
                  <CompanyIcon width="24px" height="24px" />
                </Space>
              )}
            </div>
          </div>
          <Col className="text-base font-bold flex flex-row justify-between">
            <Typography.Paragraph
              key={isCompanyRole ? "company-name" : "individual-name"}
              ellipsis={{ rows: 1 }}
              className="text-[22px] text-white !mb-0"
            >
              {isCompanyRole
                ? companyInfo?.extended_info?.shortname ||
                  companyInfo?.extended_info?.name
                : profile?.user_name ?? "No name"}
            </Typography.Paragraph>
          </Col>

          <Col className="text-sm overflow-hidden whitespace-nowrap text-ellipsis opacity-85 text-white">
            {isCompanyRole
              ? `${companyInfo?.extended_info?.industry} | ${companyInfo?.extended_info?.scale} Employees`
              : `${profile?.introduction ?? "No Introduction"}`}
          </Col>
          <Col className="text-sm text-[#606264] flex gap-1 items-center">
            <EnvironmentFilled />
            <Typography.Text
              key={isCompanyRole ? "company-city" : "individual-city"}
              className="text-[#606264] custom-alibaba-font"
            >
              {isCompanyRole
                ? companyInfo?.extended_info?.country
                : `${profile?.city ?? "No city"}, ${
                    profile?.country ?? "No country"
                  }`}
            </Typography.Text>
          </Col>
          {/* <div className="absolute bottom-[-20px] rounded-xl bg-opacity-10 bg-white backdrop-blur-[10px] px-[50px] py-2.5 border-[1px] border-solid border-opacity-5 border-white">
            <div className="flex flex-row gap-[22px] text-[#686868] text-xl items-center">
              <Tooltip
                title={`Switch to the ${
                  isCompanyRole ? "individual" : "company"
                }`}
              >
                <UserSwitchOutlined
                  className="cursor-pointer"
                  onClick={switchRole}
                />
              </Tooltip>
              <Divider
                type="vertical"
                className="border-[1px] border-[#686868] m-0"
              />
              <Tooltip
                title={`To ${isCompanyRole ? "Company" : "Personal"} Homepage`}
              >
                <HomeOutlined className="cursor-pointer" onClick={goToHome} />
              </Tooltip>
              <Divider
                type="vertical"
                className="border-[1px] border-[#686868] m-0"
              />
              <Tooltip title={`Edit ${isCompanyRole ? "Company" : "Resume"}`}>
                <EditOutlined className="cursor-pointer" onClick={editInfo} />
              </Tooltip>
            </div>
          </div> */}
        </Col>
      </div>
      <Col className="flex flex-row px-6 py-2 justify-between mb-0">
        <Col
          className="flex flex-row items-center gap-2.5"
          onClick={() =>
            navigate(
              `/discover/author/${user?.id}?role=${
                isCompanyRole ? "COMPANY" : "INDIVIDUAL"
              }`,
            )
          }
        >
          <Col>
            <Icon className="text-[26px]" component={UserFollowing} />
          </Col>
          <Col className="flex flex-col">
            <Typography.Text className="text-white text-xl font-bold">
              {formatNumber(followCount.followee_count)}
            </Typography.Text>
            <Typography.Text className="text-[#686868] text-base">
              Following
            </Typography.Text>
          </Col>
        </Col>
        <Col>
          <Divider
            type="vertical"
            className="border-[1px] border-white m-0 h-full opacity-20 rounded-sm"
          />
        </Col>
        <Col
          className="flex flex-row items-center gap-2.5"
          onClick={() =>
            navigate(
              `/discover/author/${user?.id}?role=${
                isCompanyRole ? "COMPANY" : "INDIVIDUAL"
              }`,
            )
          }
        >
          <Col>
            <Icon className="text-[26px]" component={UserFollowers} />
          </Col>
          <Col className="flex flex-col">
            <Typography.Text className="text-white text-xl font-bold">
              {formatNumber(followCount.follower_count)}
            </Typography.Text>
            <Typography.Text className="text-[#686868] text-base">
              Followers
            </Typography.Text>
          </Col>
        </Col>
      </Col>
      <div className="px-4">
        <Divider className="border-[1px] border-white/[.2] !mb-1 !mt-3" />
      </div>
      <Col className={`flex-1 ${styles.menuContent}`}>{renderMenu()}</Col>
    </Row>
  );
};
