import { Edit, SaveButton, useStepsForm } from "@refinedev/antd";
import {
  IResourceComponentsProps,
  useApiUrl,
  useTranslate,
} from "@refinedev/core";

import { DownOutlined } from "@ant-design/icons";
import { Card, Checkbox, Dropdown, Menu } from "antd";

import { Button, Col, Form, Input, Row, Select, Steps } from "antd";
import React, { useState } from "react";

import { useConfigProvider } from "../../contexts";

import { BudgetSummaryTable } from "./budgetSummaryTable";
import { JobInfoSummary } from "./jobInfoSummary";

import { Alert, Space, Statistic, Table, Tag } from "antd";
import { Typography } from "antd";
const { Text, Link } = Typography;

import { useNavigate } from "react-router-dom";

import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Avatar, Grid } from "antd";

const { Meta } = Card;

// for debugging use
// WIP: responsive design

export const JobListStatic: React.FC<IResourceComponentsProps> = () => {
  const { mode, setMode } = useConfigProvider();
  const breakpoint = Grid.useBreakpoint();
  const navigate = useNavigate();

  const goToDetail = () => {
    navigate("/jobs/detail");
  };

  return (
    <div>
      <Row
        gutter={[16, 16]}
        align="middle"
        justify="space-between"
        className={`${
          mode === "light" ? "bg-white" : "bg-white bg-opacity-20"
        } p-4 rounded-b-lg mb-10`}
      >
        <Col xs={24} sm={24} md={14} className="flex">
          <Input.Search placeholder="Search by job title, project title" />
          <Select
            className="md:ml-4 ml-2"
            placeholder="Job Status"
            style={{ width: 120 }}
            options={[
              { value: "Open", label: "Open" },
              { value: "Closed", label: "Closed" },
            ]}
          />
        </Col>

        <Col xs={24} sm={24} md={4}>
          <Button
            type="primary"
            block
            onClick={() => {
              navigate("/jobs/create");
            }}
          >
            Create a job
          </Button>
        </Col>
      </Row>

      {breakpoint.xl ? (
        <Card
          hoverable
          className="flex"
          bodyStyle={{ width: "100%" }}
          cover={
            <img
              alt="job cover"
              style={{ maxHeight: "11rem" }}
              className="h-full !w-auto !rounded-l-lg !rounded-r-none"
              src="images/job-cover-01.jpeg"
            />
          }
          onClick={() => {
            goToDetail();
          }}
        >
          <Row gutter={20}>
            <Col span={10}>
              <Meta
                title="3D Modeler (Job Title)"
                description="Elemental (Project title)"
              />
              <p className="mt-4">
                <Tag color="#87d068">Open</Tag>
              </p>
              <Text type="secondary">Create Date: 2023-12-12</Text>
            </Col>

            <Col span={14}>
              <Text>Los Angeles / Remote / Contract</Text>

              <Row gutter={16} className="mt-10">
                <Col span={8}>
                  <Statistic title="Viewed" value={10} />
                </Col>
                <Col span={8}>
                  <Statistic title="Interviewed" value={10} />
                </Col>
                <Col span={8}>
                  <Statistic title="Applied" value={10} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      ) : (
        <Card
          hoverable
          className=""
          bodyStyle={{ width: "100%" }}
          cover={
            <img
              alt="job cover"
              // style={{ width: "100%" }}
              className="w-full"
              src="images/job-cover-01.jpeg"
            />
          }
          onClick={() => {
            goToDetail();
          }}
        >
          <Row gutter={20}>
            <Col span={10}>
              <Meta
                title="3D Modeler (Job Title)"
                description="Elemental (Project title)"
              />
              <p className="mt-4">
                <Tag color="#87d068">Open</Tag>
              </p>
              <Text type="secondary">Create Date: 2023-12-12</Text>
            </Col>

            <Col span={14}>
              <Text>Los Angeles / Remote / Contract</Text>

              <Row gutter={16} className="mt-10">
                <Col span={8}>
                  <Statistic title="Viewed" value={10} />
                </Col>
                <Col span={8}>
                  <Statistic title="Interviewed" value={10} />
                </Col>
                <Col span={8}>
                  <Statistic title="Applied" value={10} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      )}

      {breakpoint.xl ? (
        <Card
          hoverable
          className="flex mt-4"
          bodyStyle={{ width: "100%" }}
          cover={
            <img
              alt="job cover"
              style={{ maxHeight: "11rem" }}
              className="h-full !w-auto !rounded-l-lg !rounded-r-none"
              src="images/job-cover-01.jpeg"
            />
          }
          onClick={() => {
            goToDetail();
          }}
        >
          <Row gutter={20}>
            <Col span={10}>
              <Meta
                title="3D Modeler (Job Title)"
                description="Elemental (Project title)"
              />
              <p className="mt-4">
                <Tag color="#666">Closed</Tag>
              </p>
              <Text type="secondary">Create Date: 2023-12-12</Text>
            </Col>

            <Col span={14}>
              <Text>Los Angeles / Remote / Contract</Text>

              <Row gutter={16} className="mt-10">
                <Col span={8}>
                  <Statistic title="Viewed" value={10} />
                </Col>
                <Col span={8}>
                  <Statistic title="Interviewed" value={10} />
                </Col>
                <Col span={8}>
                  <Statistic title="Applied" value={10} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      ) : (
        <Card
          hoverable
          className="mt-4"
          bodyStyle={{ width: "100%" }}
          cover={
            <img
              alt="job cover"
              // style={{ width: "100%" }}
              className="w-full"
              src="images/job-cover-01.jpeg"
            />
          }
          onClick={() => {
            goToDetail();
          }}
        >
          <Row gutter={20}>
            <Col span={10}>
              <Meta
                title="3D Modeler (Job Title)"
                description="Elemental (Project title)"
              />
              <p className="mt-4">
                <Tag color="#666">Closed</Tag>
              </p>
              <Text type="secondary">Create Date: 2023-12-12</Text>
            </Col>

            <Col span={14}>
              <Text>Los Angeles / Remote / Contract</Text>

              <Row gutter={16} className="mt-10">
                <Col span={8}>
                  <Statistic title="Viewed" value={10} />
                </Col>
                <Col span={8}>
                  <Statistic title="Interviewed" value={10} />
                </Col>
                <Col span={8}>
                  <Statistic title="Applied" value={10} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      )}
    </div>
  );
};
