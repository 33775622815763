import { formatJobDetails } from "@/utils/helper";
import { DownOutlined } from "@ant-design/icons";
import { Edit, SaveButton, useStepsForm } from "@refinedev/antd";
import { IResourceComponentsProps, useApiUrl } from "@refinedev/core";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Dropdown,
  Empty,
  Grid,
  Input,
  Menu,
  Modal,
  Row,
  Spin,
  Statistic,
  Tag,
} from "antd";
import { useCallback, useEffect, useMemo, useRef } from "react";
import React, { useState } from "react";
import { formatToMiddleStyle } from "../../utils/date";

import { useConfigProvider } from "../../contexts";

import { JobCoverInList } from "./jobCoverInList";

import { Typography } from "antd";
const { Text } = Typography;

import { useNavigate } from "react-router-dom";

const { Meta } = Card;

import { getPublishedJobs } from "@/pages/jobs/services";
import { axiosInstance } from "@/utils/http";
import { useInfiniteScroll, useRequest } from "ahooks";
import CompanyVerificationModal from "../../components/projects/CompanyVerificationModal";

const PAGE_SIZE = 10;

interface IJob {
  id: string;
  title: string;
  project_name: string;
  location: string;
  remote_option: string;
  employment_type: string;
  is_enabled: number;
  created_at: string;
  is_verified: number;
  total_views: number;
  total_applicants: number;
  total_interviewed: number;
  total_communicated: number;
}

interface GetMyJobsPublishedResponse {
  items: IJob[];
  total: number;
}

// WIP: responsive design

export const JobList: React.FC<IResourceComponentsProps> = () => {
  const apiUrl = useApiUrl();
  const TOKEN_KEY = "leyline-auth";
  const token = localStorage.getItem(TOKEN_KEY);
  const { mode, setMode } = useConfigProvider();
  const breakpoint = Grid.useBreakpoint();
  const navigate = useNavigate();
  const [isCompanyVerificationRequired, setIsCompanyVerificationRequired] =
    useState(false);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [selectedJobStatus, setSelectedJobStatus] = useState<string[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  const isEnabled = useMemo(() => {
    return selectedJobStatus.length === 1
      ? selectedJobStatus[0] === "Open"
        ? 1
        : 0
      : undefined;
  }, [selectedJobStatus[0], selectedJobStatus.length]);

  const fetchItems = useCallback(
    async (page: number, keyword: string, isEnabled?: number) => {
      const res = await getPublishedJobs({
        page,
        size: PAGE_SIZE,
        name: keyword,
        is_enabled: isEnabled,
      });
      return {
        total: res.data.total,
        list: res.data.items,
        nextPage:
          res.data.page < res.data.pages ? res.data.page + 1 : undefined,
      };
    },
    [],
  );

  const {
    data,
    loading,
    loadingMore,
    noMore,
    error: isError,
  } = useInfiniteScroll(
    (d) =>
      d
        ? fetchItems(d?.nextPage, searchKeyword, isEnabled)
        : fetchItems(1, searchKeyword, isEnabled),
    {
      target: ref,
      isNoMore: (d) => !d?.nextPage,
      reloadDeps: [searchKeyword, isEnabled],
    },
  );

  useEffect(() => {
    const checkCompanyInfo = async () => {
      try {
        const response = await axiosInstance.get(
          `${apiUrl}/companies/my-company`,
          {},
        );
        if (
          !response.data ||
          Object.keys(response.data).length === 0 ||
          response.data.is_verified !== true
        ) {
          setIsCompanyVerificationRequired(true);
        }
      } catch (error) {
        console.error("Failed to fetch company info:", error);
      }
    };

    checkCompanyInfo();
  }, [apiUrl]);

  if (isError) {
    return <div>Something went wrong!</div>;
  }

  const goToDetail = (jobId: string) => {
    navigate(`/jobs/detail/${jobId}`);
  };

  const handleConfirm = () => {
    navigate("/companies/show");
  };

  const handleCancel = () => {
    navigate("/users/home");
  };

  if (isCompanyVerificationRequired) {
    return (
      <CompanyVerificationModal
        isVisible={isCompanyVerificationRequired}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    );
  }

  return (
    <div className="h-full">
      <Row
        gutter={[16, 16]}
        align="middle"
        justify="space-between"
        className={`${
          mode === "light" ? "bg-white" : "bg-white bg-opacity-20"
        } p-4 rounded-b-lg mb-6`}
      >
        <Col xs={24} sm={24} md={14} className="flex">
          <Input.Search
            className="mr-2"
            value={searchKeyword}
            onChange={(e) => {
              setSearchKeyword(e.target.value);
            }}
            placeholder="Search by job title, project title"
          />

          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() => {
                    if (!selectedJobStatus.includes("Open")) {
                      setSelectedJobStatus([...selectedJobStatus, "Open"]);
                    }
                  }}
                >
                  Open
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    if (!selectedJobStatus.includes("Closed")) {
                      setSelectedJobStatus([...selectedJobStatus, "Closed"]);
                    }
                  }}
                >
                  Closed
                </Menu.Item>
              </Menu>
            }
          >
            <Button>
              {selectedJobStatus.length > 0
                ? selectedJobStatus.map((status) => (
                    <Tag
                      closable
                      onClose={() => {
                        setSelectedJobStatus(
                          selectedJobStatus.filter((cur) => {
                            return cur !== status;
                          }),
                        );
                      }}
                      key={status}
                    >
                      {status}
                    </Tag>
                  ))
                : "Job Status"}
              <DownOutlined />
            </Button>
          </Dropdown>
        </Col>

        <Col xs={24} sm={24} md={4}>
          <Button
            type="primary"
            block
            onClick={() => {
              navigate("/jobs/create");
            }}
          >
            Create a job
          </Button>
        </Col>
      </Row>

      {!data?.total ? (
        <Empty />
      ) : (
        <div
          ref={ref}
          className="p-3 pt-0 overflow-auto"
          style={{ height: "calc(100% - 86px)" }}
        >
          {data?.list?.map((job) => {
            return breakpoint.xl ? (
              <Card
                key={job.id}
                hoverable
                className="flex mb-3"
                bodyStyle={{ width: "100%" }}
                cover={
                  <JobCoverInList
                    // @ts-ignore
                    thumbnail={job.project_thumbnail_url}
                    useXL={true}
                  />
                }
                onClick={() => {
                  goToDetail(job.id);
                }}
              >
                <Row gutter={20}>
                  <Col span={10}>
                    <Meta title={job.title} description={job.project_name} />
                    <p className="mt-4">
                      {job.is_enabled ? (
                        <Tag color="#87d068">Open</Tag>
                      ) : (
                        <Tag color="#666">Closed</Tag>
                      )}

                      {job.is_verified ? (
                        <Tag color="#87d068">Verified</Tag>
                      ) : (
                        <></>
                      )}
                    </p>
                    <Text type="secondary">
                      Local Create Date: {formatToMiddleStyle(job.created_at)}
                    </Text>
                  </Col>

                  <Col span={14}>
                    <Text>
                      {formatJobDetails(
                        job.location,
                        job.remote_option,
                        job.employment_type,
                      )}
                    </Text>

                    <Row gutter={16} className="mt-10">
                      <Col span={8}>
                        <Statistic title="Viewed" value={job.total_views} />
                      </Col>
                      <Col span={8}>
                        <Statistic
                          title="Communicated"
                          value={job.total_communicated}
                        />
                      </Col>
                      <Col span={8}>
                        <Statistic
                          title="Applied"
                          value={job.total_applicants}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            ) : (
              <Card
                key={job.id}
                hoverable
                className="mb-3"
                bodyStyle={{ width: "100%" }}
                cover={
                  <JobCoverInList
                    // @ts-ignore
                    thumbnail={job.project_thumbnail_url}
                    useXL={false}
                  />
                }
                onClick={() => {
                  goToDetail(job.id);
                }}
              >
                <Row gutter={20}>
                  <Col span={10}>
                    <Meta title={job.title} description={job.project_name} />
                    <p className="mt-4">
                      {job.is_enabled ? (
                        <Tag color="#87d068">Open</Tag>
                      ) : (
                        <Tag color="#666">Closed</Tag>
                      )}

                      {job.is_verified ? (
                        <Tag color="#87d068">Verified</Tag>
                      ) : (
                        <></>
                      )}
                    </p>
                    <Text type="secondary">
                      Local Create Date: {formatToMiddleStyle(job.created_at)}
                    </Text>
                  </Col>

                  <Col span={14}>
                    <Text>
                      {job.location || ""} / {job.remote_option} /{" "}
                      {job.employment_type}
                    </Text>

                    <Row gutter={16} className="mt-10">
                      <Col span={8}>
                        <Statistic title="Viewed" value={job.total_views} />
                      </Col>
                      <Col span={8}>
                        <Statistic
                          title="Communicated"
                          value={job.total_communicated ?? 0}
                        />
                      </Col>
                      <Col span={8}>
                        <Statistic
                          title="Applied"
                          value={job.total_applicants}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            );
          })}
          <div className="text-center">
            {loading && <Spin />}
            {loadingMore && <Spin tip="Loading more..." />}
            {noMore && <div>No more data</div>}
          </div>
        </div>
      )}
    </div>
  );
};
